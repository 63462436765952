const SEASONS = "seasons";
const CATEGORIES = "categories";
const PRODUCTS = "products";
const ASSETS = "assets";

export default {
  season: {
    all: `/${SEASONS}`,
    withCleanName: (name?: string) => `/${SEASONS}/${name}`,
    seasonTypes: `/seasonTypes`
  },
  axis: {
    all: `/axis`,
  },
  category: {
    all: `/${CATEGORIES}`,
    withId: (id: number) => `/${CATEGORIES}/${id}`,
  },
  subCategory: {
    withCategoryId: (id?: number) => `/${CATEGORIES}/${id}/subcategories`,
    withId: (categoryId: number, subCategoryId: number) => `/${CATEGORIES}/${categoryId}/subcategories/${subCategoryId}`,
  },
  product: {
    all: `/${PRODUCTS}`,
    withId: (id?: number) => `/${PRODUCTS}/${id}`,
  },
  asset: {
    all: `/${ASSETS}/types`,
  },
};
