import React from 'react';

import { StyledButton } from '@components/Button/Button.styled';
import * as Icons from '@mui/icons-material';
import { Icon } from '@src/components';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';

interface IButtonProps {
  action: () => void;
  format?: Format;
  text?: string;
  icon?: keyof typeof Icons;
  type?: ComponentType;
  iconWidth?: string;
  iconHeight?: string;
  iconColor?: string;
}

function Button({
  action,
  text,
  icon,
  format,
  type,
  iconWidth,
  iconHeight,
  iconColor,
}: IButtonProps) {
  const handleClick = () => {
    if (action) {
      action();
    }
  };

  return (
    <StyledButton type={type} format={format} onClick={handleClick}>
      {icon
        ? (
        <Icon
          icon={icon}
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          type={type ?? ComponentType.Button}
          iconColor={iconColor}
        />
          )
        : (
        <></>
          )}
      {text && (
        <StyledText type={type ?? ComponentType.Button} format={format}>
          {text}
        </StyledText>
      )}
    </StyledButton>
  );
}

export default Button;
