import styled from 'styled-components';

export const StyleDivButtonSeason = styled.div`
  height: 2.8rem;
  display: flex;
  width: max-content;
  flex-direction: row;
  flex-wrap: unset;
  align-items: center;
  margin: 0.2rem 0 1rem 0;
`;

export const StyleLockOrEdit = styled.div<{ lock?: boolean }>`
  margin: 0 0 0.1rem 0;
  padding: 0.3rem;
  transition: transform 0.3s ease;
  background: ${(props) =>
    props.lock ? 'var(--error-todo-color)' : 'var(--validated-color)'};
  border-radius: var(--home-lockOrEdit-btn-border-radius);
`;

export const StyleDownloadBtn = styled.div`
  padding: 0.3rem;
  transition: transform 0.3s ease;
  cursor: pointer;
  background: var(--text-color);
  border-radius: var(--home-download-btn-border-radius);
`;
