import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { StyledElementsSeasonList } from '@components/SeasonsList/SeasonsList.styled';
import { OverloadSeason } from '@src/components';
import { ComponentType } from '@utils/enum.utils';
import { StyledBox, StyledText, StyleHorizontallySeparator } from '@utils/style.utils';
import { setSelectedTabId } from '@store/navigationbar/navigationBar.reducer';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { selectSeasons } from '@store/season/season.selector';


const SeasonsList: React.FC = () => {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const dispatch = useAppDispatch();
  const seasonList = useAppSelector(selectSeasons);

  return (
    <div>
      {seasonList.length > 0 && seasonList.map((item) => (
        <StyledBox key={item.year}>
          <StyledText type={ComponentType.SubTitle}>{item.year}</StyledText>
          <StyleHorizontallySeparator />

          {item.seasons.map((seasonGroup) => (
            <div key={seasonGroup.seasonId}>
              <StyledText type={ComponentType.SubSubTitle}>
                {t(seasonGroup.seasonName)}
              </StyledText>
              <StyledElementsSeasonList>
                {seasonGroup.elements.map((element) => (
                  <OverloadSeason
                    key={element.id}
                    action={() => {
                      dispatch(setSelectedTabId(element.cleanName));
                      const redirectWithParams = generatePath(
                        "season/:cleanName",
                        { cleanName: element.cleanName },
                      );
                      navigation(redirectWithParams);
                    }}
                    element={element}
                  />
                ))}
              </StyledElementsSeasonList>
            </div>
          ))}
        </StyledBox>
      ))}
    </div>
  );
}

export default SeasonsList;
