import { defaultApi } from 'store/api/api';
import endpoints from 'store/api/endpoints';
import { METHODS } from 'types';
import {
  PostProductDetailsRequest,
  ProductDetailsConsolidated,
  ProductGroupedByLevel,
  ProductRequest,
  ProductWithSeasonDetailsRequest
} from 'types/api/product';

export const productApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getProductsWithSeason: build.query<
      ProductGroupedByLevel[],
      ProductWithSeasonDetailsRequest
    >({
      query: (params) => ({
        url: endpoints.product.all,
        params
      })
    }),
    getProduct: build.query<
      ProductDetailsConsolidated,
      ProductRequest
    >({
      query: ({ id }) => ({
        url: endpoints.product.withId(id)
      }),
    }),
    postProduct: build.mutation<
      ProductDetailsConsolidated,
      PostProductDetailsRequest
    >({
      query: (product) => {
        return {
          url: endpoints.product.all,
          method: METHODS.POST,
          body: product,
        };
      },
    }),
    putProduct: build.mutation<
      ProductDetailsConsolidated,
      Partial<ProductDetailsConsolidated>
    >({
      query: (arg) => {
        const { id, ...ProductDetailsRequest } = arg;
        return {
          url: endpoints.product.withId(id),
          method: METHODS.PUT,
          body: ProductDetailsRequest,
        };
      }
    }),
    delProduct: build.mutation<
      void,
      ProductRequest
    >({
      query: (arg) => {
        const { id } = arg;
        return {
          url: endpoints.product.withId(id),
          method: METHODS.DELETE,
        };
      }
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProductsWithSeasonQuery,
  useLazyGetProductsWithSeasonQuery,
  useLazyGetProductQuery,
  usePostProductMutation,
  usePutProductMutation,
  useDelProductMutation
} = productApi;
