import { createSlice } from '@reduxjs/toolkit';
import { AxisDetails } from '@src/types';

interface AxisState {
  axisType: AxisDetails[];
  selectedAxis?: AxisDetails
}

const initialState: AxisState = {
  axisType: [],
};

const axisSlice = createSlice({
  name: "axis",
  initialState,
  reducers: {
    setAxisType: (state, { payload }: { payload: AxisDetails[] }) => {
      state.axisType = payload;
    },
    setSelectedAxis: (state, { payload }: { payload: AxisDetails }) => {
      state.selectedAxis = payload;
    }
  },
});

export const {
  setAxisType,
  setSelectedAxis
} = axisSlice.actions;

export default axisSlice.reducer;
