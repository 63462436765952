export const BriefAsset = 'BRIEF ASSET';
export const NewSeason = 'New Season';
export const Home = 'Home';
export const SeasonalBrief = 'Seasonal Brief';

export const X_ID_TOKEN = "x-id-token";

export const X_API_KEY = "x-api-key";

export const X_ACCESS_TOKEN = "x-access-token";

export const OKTA_API_KEY = "xapikey";

export enum CarrouselText {
  ImageInput = 'ImageInput',
  AlertUpload = 'Please upload an image file.',
  Next = 'NEXT',
  Back = 'BACK',
  Plus = '+',
}

export enum FilesText {
  UploadFile = 'Choose a file',
  SelectFile = 'Fichiers sélectionnés :'
}

export enum ProductText {
  AddProduct = 'ADD PRODUCT',
  NewProduct = 'New Product',
  Visuals = ' Visuals ',
  SKUs = ' SKUs ',
  NumberofSKUs = 'Number of SKUs ',
  Sku = 'SKU',
  AlertUpload = 'Please upload an image file.',
  Upload = 'UPLOAD',
  ImageInput = 'ImageInput',
  AdditionalImage = 'Additional photos',
  AmbitionsSellOut = 'Sell out Ambition',
  Benefit = 'Benefit/Scent',
  KeyElementsToHighlightInVisuals = 'Key elements to highlight in visuals',
  MopOwner = 'MOP owner',
  DigitOwner = 'Digit owner',
  MarketingProductOwner = 'Marketing Product owner',
  LastUpdate = ' Last update : ',
  Product = ' product ',
  Products = ' products ',
  Name = 'Name',
  Category = 'Category',
  SubCategory = 'Subcategory',
  LevelOfActivation = 'Level of activation',
  AssortimentFile = 'Assortiment file',
  FileInput = 'FileAssortmentInput',
  AddExcel = 'Add an excel',
  Image = 'image/*',
  DragImage = 'or drag images',
  DragPackshot = 'or drag Packshots',
  BigBet = 'BIG BET',
  Strategique = 'STRATEGIQUE',
  Standard = 'STANDARD',
  Pillar = 'PILLAR',
  Status = 'Status',
  SpecificPackaging = 'Specific Packaging',
  OneShot = 'One Shot',
  OneYearCatalogue = 'One year Catalogue',
  Catalogue = 'Catalogue',
  Assets = 'assets'
}
