export enum TAG_TYPES {
  SEASON = "SEASON",
  SEASON_TYPES = "SEASON_TYPES",
  AXIS = "AXIS",
}

export enum SEASON_TYPES {
  SPRING = "SPRING",
  SUMMER = "SUMMER",
  FALL = "FALL",
  HOLIDAY = "HOLIDAY",
  MEDIA_ALWAYS_ON = "MEDIA ALWAYS-ON",
}
