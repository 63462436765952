import { createSlice } from '@reduxjs/toolkit';
import { CategoriesDetails } from 'types/api/category';

interface CategoryState {
  categoryType: CategoriesDetails[];
}

const initialState: CategoryState = {
  categoryType: [],
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setCategoryType: (state, { payload }: { payload: CategoriesDetails[] }) => {
      state.categoryType = payload;
    },
  },
});

export const {
  setCategoryType,
} = categorySlice.actions;

export default categorySlice.reducer;
