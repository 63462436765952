import styled from 'styled-components';
import { css } from '@emotion/react';

export const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;


export const HeaderColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-top: 20px;
`;

export const HeaderImageColumn = styled.div`
  display: flex;
  align-items: center;
`;

export interface IHeaderDetailsColumn {
  margin?: string;
  height?: string;
}

export const HeaderDetailsColumn = styled.div<IHeaderDetailsColumn>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: ${({ margin }) => (margin ? margin : "20px")};
  height: ${({ height }) => (height ? height : "16vh")};
`;

export const HeaderDetailsLine = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderImage = styled.img`
  border: 2px solid black;
  border-radius: 10px;
  width: 150px;
  height: 150px;
  object-fit: cover;
`;

export interface IHeaderDetailsLine {
  width?: string
}

export const HeaderItem = styled.div<IHeaderDetailsLine>`
  margin-bottom: 10px;
  margin-right: 20px;
  width: ${({ width }) => (width ? width : "")};
`;

export const HeaderItemRightAlign = styled(HeaderItem)`
  margin-right: 0;
  margin-bottom: 6px;
`;

export const HeaderItemLabel = styled.label`
  margin-top: 1px;
  color: var(--input-background-color);
  font-size: 14px;
`;

export const HeaderItemLabelRight = styled.label`
    margin-top: 20px;
    margin-left: 2px;
    margin-bottom: 6px;
    padding: 0;
    font-size: 14px;
    align-self: flex-end;
    text-align: right;
`;

export const HeaderItemLabelRightBold = styled(HeaderItemLabelRight)`
  font-weight: bold;
  font-size: 19px;
`;

export const HeaderBorderedItem = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 5px;
`;

export interface IEditableField {
  disabled: boolean
}

export const EditableField = styled.input<IEditableField>`
  border: none;
  background: none;
  margin-left: 2px;
  padding: 0;
  font-size: 14px;
  align-self: start;
  &:disabled {
      color: black;
  }
  ${({ disabled }) => !disabled &&
    (
      css`
        &:hover {
          outline: none;
          border-bottom: 1px solid black;
        }
        &:focus {
          outline: none;
          border-bottom: 1px solid black;
        }
      `
    )
  }
`;


export const EditableTitle = styled(EditableField)`
  font-weight: bold;
  font-size: 26px;
  font-family: ITC;
  &:disabled {
      color: black;
  }
`;

