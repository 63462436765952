import styled, { css } from 'styled-components';

interface IStyledPopup {
  isopen: boolean
}

interface IStyledPopupContent {
  width?: string
}

export const StyledPopup = styled.div<IStyledPopup>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  display: none;

  ${({ isopen }) =>
    isopen &&
    css`
      display: flex;
      z-index: 10;
    `}
`;

export const StyledPopupContent = styled.div<IStyledPopupContent>`
  width: ${(props) => props.width ?? ''};
  background-color: var(--box-background-color);
  border-radius: var(--border-radius);
  border: 3px solid var(--button-background-color);
  display: flex;
  padding: 1rem 2rem 1rem 2rem;
  flex-direction: column;
  align-items: center;
`;

export const StyledPopupProductContent = styled.div<IStyledPopupContent>`
  width: ${(props) => props.width ?? ''};
  background-color: var(--box-background-color);
  border-radius: var(--border-radius);
  border: 3px solid var(--button-background-color);
  display: flex;
  padding: 0 3rem 0 2rem;
  flex-direction: column;
  align-items: center;
`;

export const StyledPopupHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const StyledSubmitButton = styled.div`
  display: flex;
  justify-content: end;
`;

export const AddButtonDialogStyle = styled.div`
  position: absolute;
  left: 65%;
  width: 100%;
  margin-top: 5%;
`;

export const CloseDialogButtonStyle = styled.div`
  position: absolute;
  left: 90%;
`;
