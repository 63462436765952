import styled from 'styled-components';

export const StyledProductsColumnList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;
    margin: 0 0 0.5rem 0;

    > * {
        position: relative;
    }
`;

export const StyleCategorySection = styled.div`
  margin: 0 3rem;
`;
