import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { NavigationBar, StyleGlobalNavigationBar } from '@src/components';
import { useLazyGetSeasonsQuery, useLazyGetSeasonsTypeQuery } from '@store/api/season';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { setSeasons, setSeasonsType } from '@store/season/season.reducer';
import { useLazyGetAxisQuery } from '@store/api/axis';
import { setAxisType } from '@store/axis/axis.reducer';
import { useLazyGetCategoriesQuery } from '@store/api/categories';
import { setCategoryType } from '@store/category/category.reducer';
import { selectCredentials } from '@store/auth/auth.selector';

const Root = () => {
  const dispatch = useAppDispatch()
  const credentials = useAppSelector(selectCredentials);
  const [getSeasonType, seasonsType] = useLazyGetSeasonsTypeQuery();
  const [getSeasons, seasons] = useLazyGetSeasonsQuery();
  const [getAxis, axis] = useLazyGetAxisQuery();
  const [getCategories, categories] = useLazyGetCategoriesQuery();
  useEffect(() => {
    if (credentials) {
      getSeasonType();
      getSeasons();
      getAxis();
      getCategories();
    }
  }, [credentials]);

  useEffect(() => {
    if (seasonsType.data) {
      dispatch(setSeasonsType(seasonsType.data));
    }
  }, [seasonsType.data]);

  useEffect(() => {
    if (seasons.data) {
      dispatch(setSeasons(seasons.data));
    }
  }, [seasons.data]);

  useEffect(() => {
    if (axis.data) {
      dispatch(setAxisType(axis.data));
    }
  }, [axis.data]);

  useEffect(() => {
    if (categories.data) {
      dispatch(setCategoryType(categories.data));
    }
  }, [categories.data]);

  return (
    <>
      <StyleGlobalNavigationBar>
        <NavigationBar/>
      </StyleGlobalNavigationBar>
      <div style={{ width: '100%' }}>
        <Outlet />
      </div>
    </>
  );
};

export default Root;
