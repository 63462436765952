import styled, { css } from 'styled-components';

export const StyleGlobalNavigationBar = styled.div`
  display: flex !important;
  min-width: 250px;
  overflow-y: auto;
`;

export const StyledNavigationBar = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  flex-direction: column;
  background-color: var(--menu-background-color);
`;

export const StyledTitleNavigationBar = styled.div`
  background-color: var(--menu-accentuation-color);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
      filter: invert(1);
  }
`;

export const StyledContentListNavigationBar = styled.div`
  width: 100%;
  margin-top: 1rem;
  overflow: hidden;
  height: 100%;
`;

export const StyledIconNavigationBar = styled.div`
  color: #fff;
  transition: transform 0.3s ease;
  margin-right: 0.4rem;
`;

export const StyledContentNavigationBar = styled.div<{
  isLogOut?: boolean;
  show?: boolean;
  depth?: number;
  clickable?: boolean;
  clicked?: boolean;
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  font-size: 0.7rem;
  position: relative;
  bottom: auto;
  left: auto;
  width: auto;
  margin-bottom: auto;
  color: #fff;
  padding: 0.2rem 0.5rem;
  align-items: center;
  margin-left: ${({ depth }) => (depth && depth > 0 ? `${depth}rem` : '0')};
    
  ${({clickable}) => (clickable && 
    css`
        &:hover {
        background-color: #444343;
        cursor: pointer;
    }`)}

  ${({ clicked}) => (clicked &&
    css`
      background-color: #444343;
      cursor: pointer;
    `)}
    

  ${({ isLogOut }) =>
    isLogOut &&
    `
        position: fixed;
        bottom: 0;
        left: 0;
        margin-bottom: 1rem;
    `}
`;
