import React, { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Carousel from '@components/Carousel';
import FileUploader from '@components/FileUploader/FileUploader';
import ImageComponent from '@components/Image';
import { StyledImageBubble } from '@components/Image/Image.styled';
import flagChina from '@components/Image/flag/flag_china.png';
import flagUsaAndCanada from '@components/Image/flag/flag_usa_canada.png';
import UploadZone from '@components/UploadZone';
import { StyledSelectInput } from '@components/inputs/Inputs.styled';
import { AddProductDialogContent, StyledDivInput, } from '@components/popups/AddProductDialog/AddProductDialog.styled';
import { StyledPopupHeader, StyledPopupProductContent, } from '@components/popups/PopupManager/PopupManager.styled';
import { Button, FormSelectInput, FormTextInput } from '@src/components';
import { CarrouselText, ProductText } from '@utils/constantes.utils';
import { GeographicScope, Levels, ProductStatus, } from '@utils/data/enums/generals.enum';
import { IconName } from '@utils/data/enums/icons.enum';
import { ComponentType, Format } from '@utils/enum.utils';
import {
  BasicButton,
  StyleDisplayColumn,
  StyleDisplayRow,
  StyleDisplayRowEnd,
  StyledText,
  StyleVerticallySeparator,
} from '@utils/style.utils';
import { useGetCategoryWithAxisQuery } from '@store/api/categories';
import { useAppSelector } from '@store/hooks';
import { selectedSeason } from '@store/season/season.selector';
import { useLazyGetSubCategoriesWithCategoryIdQuery } from '@store/api/subCategories';
import { getIdFromName, getNameFromId } from '@utils/fonctions.utils';
import { selectAxisType } from '@store/axis/axis.selector';
import { usePostProductMutation } from '@store/api/product';
import { PostProductDetailsRequest, ProductForm } from 'types/api/product';
import { SubCategoriesDetails } from 'types/api/subCategory';
import { generatePath, useNavigate } from 'react-router-dom';
import { selectProductCreated, selectProductId } from '@store/product/product.selector';

interface AddProductDialogProps {
  closePopup: () => void;
  isOpen?: boolean;
  initValues?: ProductForm;
  category?: string
}

const AddProductDialog: FC<AddProductDialogProps> = ({
  closePopup,
  isOpen,
  initValues,
  category
}) => {
  const navigate = useNavigate();
  const selectedSeasonState = useAppSelector(selectedSeason);
  const axisType = useAppSelector(selectAxisType);
  const categories = useGetCategoryWithAxisQuery({ axisId: selectedSeasonState?.axisId }).data;
  const [getSubCategories] = useLazyGetSubCategoriesWithCategoryIdQuery();
  const [createProduct] = usePostProductMutation();
  const productCreatedState = useAppSelector(selectProductCreated);
  const productId = useAppSelector(selectProductId);
  const { t } = useTranslation();
  const [carrouselImageDisplay, setCarrouselImageDisplay] = useState(false);
  const [subCategoriesList, setSubCategoriesList] = useState<SubCategoriesDetails[]>([]);
  const [additionalImageSrc] = useState<string[]>(initValues?.additionalPhotos || []);
  const [addProductDialogPage, setAddProductDialogPage] = useState(1);
  const [imageSrc, setImageSrc] = useState<string[]>(initValues?.visuals || []);
  const [formCategoryValue, setFormCategoryValue] = useState(category && categories ? getIdFromName(category, categories).toString() : "");
  const popupRef = useRef<HTMLDivElement>(null);
  const [formSpecificPackagingValue, setFormSpecificPackagingValue] =
    useState("");
  const [formAssortmentFileValue, setFormAssortmentFileValue] = useState<string>(initValues?.assortmentFiles[0] || "");
  const [highlighted, setHighlighted] = useState(false);
  const methods = useForm<ProductForm>({
    defaultValues: {
      name: initValues?.name || "",
      subCategoryName: initValues?.subCategoryName || "",
      level: initValues?.level,
      numberOfSkus: initValues?.numberOfSkus || "0",
      sellOutAmbition: initValues?.sellOutAmbition || "",
      status: initValues?.status || ProductStatus.ONE_SHOT,
      benefit: initValues?.benefit || "",
      seasonId: `${selectedSeasonState?.id}`,
      mopOwner: initValues?.mopOwner || "",
      productOwner: initValues?.productOwner || "",
      additionalPhotos: additionalImageSrc,
      visuals: imageSrc,
      assortmentFiles: [formAssortmentFileValue],
      elementToHighlight: "",
      geographicScope: GeographicScope.NULL
    }
  });

  const { watch, handleSubmit } = methods;

  const name = watch("name");
  const subcategoryName = watch("subCategoryName");
  const level = watch("level");

  useEffect(() => {
    if (productCreatedState) {
      const redirectWithParams = generatePath(
        "/product/:id",
        { id: productId?.toString() || null },
      );
      navigate(redirectWithParams);
    }
  }, [productCreatedState]);

  const handleSelectFlag = (flag: string) => {
    if (flag === formSpecificPackagingValue) {
      setFormSpecificPackagingValue("");
    } else {
      setFormSpecificPackagingValue(flag);
    }
  };

  const handleAddProduct = async (formValues: ProductForm) => {
    const productValues: PostProductDetailsRequest = {
      name: formValues.name,
      status: formValues.status,
      level: formValues.level,
      mopOwner: formValues.mopOwner,
      productOwner: formValues.productOwner,
      geographicScope: formValues.geographicScope,
      elementToHighlight: formValues.elementToHighlight,
      benefit: formValues.benefit,
      sellOutAmbition: formValues.sellOutAmbition,
      assortmentFiles: formValues.assortmentFiles,
      additionalPhotos: formValues.additionalPhotos,
      visuals: formValues.visuals,
      seasonId: parseInt(formValues.seasonId),
      subCategoryId: getIdFromName(formValues.subCategoryName, subCategoriesList),
      numberOfSkus: parseInt(formValues.numberOfSkus),
    }
    createProduct(productValues);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      closePopup();
    }
  };

  const handleAssortmentFiles = (fileNames: string[]) => {
    setFormAssortmentFileValue(fileNames[0]);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <StyledPopupProductContent ref={popupRef}>
      {selectedSeasonState &&
        <form onSubmit={handleSubmit(handleAddProduct)}>
          {addProductDialogPage === 1 ? (
            <AddProductDialogContent>
              <StyledPopupHeader>
                <Button
                  action={closePopup}
                  icon={IconName.CROSS}
                  format={Format.Classic}
                  type={ComponentType.Classic}
                />
              </StyledPopupHeader>
              <StyledText type={ComponentType.Title}>
                {t(ProductText.NewProduct)}
              </StyledText>
              <StyleDisplayRow gap="2rem">
                <StyleDisplayColumn>
                  <StyleDisplayRow>
                    <UploadZone
                      addProductDialogPage={addProductDialogPage}
                      carrouselDisplay={carrouselImageDisplay}
                      highlighted={highlighted}
                      imageSrc={imageSrc}
                      type={t(ProductText.DragPackshot)}
                      setHighlighted={setHighlighted}
                      setCarrouselImageDisplay={setCarrouselImageDisplay}
                      setImageSrc={setImageSrc}
                    />
                    <Carousel
                      addProductDialogPage={addProductDialogPage}
                      carrouselDisplay={carrouselImageDisplay}
                      highlighted={highlighted}
                      imageSrc={imageSrc}
                      setHighlighted={setHighlighted}
                      setCarrouselImageDisplay={setCarrouselImageDisplay}
                      setImageSrc={setImageSrc}
                    />
                  </StyleDisplayRow>
                  <StyledDivInput>
                    <StyledText type={ComponentType.FormInput}>
                      {t(ProductText.SpecificPackaging)}
                    </StyledText>
                    <StyleDisplayRow>
                      <StyledImageBubble
                        isFlagSelector
                        isHiglighted={flagChina === formSpecificPackagingValue}
                        onClick={() => {
                          handleSelectFlag(flagChina);
                        }}
                      >
                        {flagChina && <ImageComponent url={flagChina} />}
                      </StyledImageBubble>
                      <StyledImageBubble
                        isFlagSelector
                        isHiglighted={
                          flagUsaAndCanada === formSpecificPackagingValue
                        }
                        onClick={() => {
                          handleSelectFlag(flagUsaAndCanada);
                        }}
                      >
                        {flagUsaAndCanada && (
                          <ImageComponent url={flagUsaAndCanada} />
                        )}
                      </StyledImageBubble>
                    </StyleDisplayRow>
                  </StyledDivInput>
                </StyleDisplayColumn>

                <StyleDisplayColumn>
                  <FormTextInput
                    title={ProductText.Name}
                    control={methods.control}
                    name="name"
                    isMandatory
                  />

                  <FormSelectInput
                    title={ProductText.LevelOfActivation}
                    control={methods.control}
                    name="level"
                    isMandatory
                    options={[
                      { value: Levels.BIGBET, label: t("BigBet") },
                      { value: Levels.STRATEGIC, label: t("Strategic") },
                      { value: Levels.CLASSIC, label: t("Standard") },
                      { value: Levels.PILAR, label: t("Pillar") },
                    ]}
                  />

                  <div>
                    <StyledText type={ComponentType.FormInput}>
                      {t(ProductText.Category)} *
                    </StyledText>
                    <StyledSelectInput
                      value={formCategoryValue}
                      onChange={async (e) => {
                        setFormCategoryValue(e.target.value);
                        const { data } = await getSubCategories({ category: getIdFromName(e.target.value, categories) });
                        if (data) {
                          setSubCategoriesList(data);
                          if (data.length === 1) {
                            methods.setValue("subCategoryName", data[0].name);
                          }
                        }
                      }}
                    >
                      <option value="">Select option</option>
                      {categories?.map((category) => (
                        <option key={category.name} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </StyledSelectInput>
                  </div>

                  {subCategoriesList.length > 1 && <FormSelectInput
                    title={ProductText.SubCategory}
                    control={methods.control}
                    name="subCategoryName"
                    isMandatory
                    options={subCategoriesList.map((subCategory) => ({
                      value: subCategory.name,
                      label: subCategory.name,
                    })) || []}
                  />}

                  <FormSelectInput
                    title={ProductText.Status}
                    control={methods.control}
                    name="status"
                    isMandatory
                    options={[
                      {
                        value: ProductStatus.ONE_SHOT,
                        label: t(ProductText.OneShot),
                      },
                      {
                        value: ProductStatus.CATALOGUE,
                        label: t(ProductText.Catalogue),
                      }
                    ]}
                  />
                </StyleDisplayColumn>
              </StyleDisplayRow>

              <StyleDisplayRowEnd>
                <Button
                  action={() => {
                    setAddProductDialogPage(2);
                  }}
                  type={ComponentType.Button}
                  format={Format.Thin}
                  text={t(CarrouselText.Next)}
                />
              </StyleDisplayRowEnd>
            </AddProductDialogContent>
          ) : (
            <div>
              <StyledPopupHeader>
                <Button
                  action={() => {
                    closePopup();
                  }}
                  icon={IconName.CROSS}
                  format={Format.Classic}
                  type={ComponentType.Classic}
                />
              </StyledPopupHeader>
              <StyleDisplayRow
                justifycontent="space-between"
                alignitems="flex-start"
              >
                <StyledText margin="1rem 10rem 0 0" type={ComponentType.Title}>
                  {t(ProductText.NewProduct)}
                </StyledText>
                <StyledPopupHeader>
                  <StyledText
                    fontSize="18px"
                    margin="1rem 0 0"
                  >{`${selectedSeasonState?.name} ${getNameFromId(selectedSeasonState?.axisId, axisType)}`}</StyledText>
                  {name && (
                    <StyledText fontSize="12px" margin="0">
                      {name}
                    </StyledText>
                  )}
                  {formCategoryValue && subcategoryName && formCategoryValue === subcategoryName ? (
                    <StyledText fontSize="12px" margin="0">
                      {formCategoryValue}
                    </StyledText>
                  ) : (
                    <StyledText fontSize="12px" margin="0">
                      {subcategoryName} - {formCategoryValue}
                    </StyledText>
                  )}
                  {level && (
                    <StyledText fontSize="12px" margin="0">
                      {level}
                    </StyledText>
                  )}
                </StyledPopupHeader>
              </StyleDisplayRow>

              <StyleDisplayColumn>
                <StyleDisplayRow>
                  <StyleDisplayColumn>
                    <FormTextInput
                      title={ProductText.Benefit}
                      control={methods.control}
                      name="benefit"
                      isMandatory
                    />
                    <FormTextInput
                      type="number"
                      title={ProductText.NumberofSKUs}
                      control={methods.control}
                      name="numberOfSkus"
                      isMandatory
                    />
                    <FormTextInput
                      title={ProductText.AmbitionsSellOut}
                      control={methods.control}
                      name="sellOutAmbition"
                      isMandatory
                    />
                  </StyleDisplayColumn>
                  <StyleVerticallySeparator />
                  <StyleDisplayColumn>
                    <FormTextInput
                      title="Key Elements to Highlight in Visuals"
                      control={methods.control}
                      name="elementToHighlight"
                      isMandatory
                    />

                    <FormTextInput
                      title={ProductText.MopOwner}
                      control={methods.control}
                      name="mopOwner"
                      isMandatory
                    />

                    <FormTextInput
                      title={ProductText.MarketingProductOwner}
                      control={methods.control}
                      name="productOwner"
                      isMandatory
                    />
                  </StyleDisplayColumn>
                  <StyleVerticallySeparator />
                  <StyleDisplayColumn>
                    <StyledText type={ComponentType.FormInput}>
                      {t(ProductText.AssortimentFile)}
                    </StyledText>
                    <FileUploader handleFiles={handleAssortmentFiles} />
                    <StyledText type={ComponentType.FormInput}>
                      {t(ProductText.AdditionalImage)}
                    </StyledText>
                    <UploadZone
                      addProductDialogPage={addProductDialogPage}
                      carrouselDisplay={carrouselImageDisplay}
                      highlighted={highlighted}
                      imageSrc={imageSrc}
                      type={t(ProductText.DragPackshot)}
                      setHighlighted={setHighlighted}
                      setCarrouselImageDisplay={setCarrouselImageDisplay}
                      setImageSrc={setImageSrc}
                    />
                    <Carousel
                      addProductDialogPage={addProductDialogPage}
                      carrouselDisplay={carrouselImageDisplay}
                      highlighted={highlighted}
                      imageSrc={imageSrc}
                      setHighlighted={setHighlighted}
                      setCarrouselImageDisplay={setCarrouselImageDisplay}
                      setImageSrc={setImageSrc}
                    />
                  </StyleDisplayColumn>
                </StyleDisplayRow>
                <StyleDisplayRow justifycontent="space-between">
                  <Button
                    action={() => {
                      setAddProductDialogPage(1);
                    }}
                    type={ComponentType.Button}
                    format={Format.Thin}
                    text={t(CarrouselText.Back)}
                  />
                  <BasicButton type="submit">
                    <Button
                      action={() => {}}
                      type={ComponentType.Button}
                      format={Format.Thin}
                      text={t(ProductText.AddProduct)}
                    />
                  </BasicButton>
                </StyleDisplayRow>
              </StyleDisplayColumn>
            </div>
          )}
        </form>
      }
    </StyledPopupProductContent>
  );
}

export default AddProductDialog;
