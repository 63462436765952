import styled, { css } from 'styled-components';

interface IStyledImgUpload {
  isDragOver?: boolean;
  isDisplay?: boolean;
  currentIndex?: number;
  addProductDialogPage?: number
}

export const StyledBtnUpload = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledImgUpload = styled.div<IStyledImgUpload>`
  display: ${(props) => (props.isDisplay ? 'none' : 'flex')};
  position: relative;
  text-align: center;
  border-radius: 12px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  background: ${(props) =>
    props.isDragOver ? 'var(--light-grey-color)' : '#D9D9D9'};
  transition: all 0.3s ease;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  ${({ addProductDialogPage }) => {
    switch (addProductDialogPage) {
      case 1:
        return css`
          height: 12rem;
          width: 12rem;
        `;
      case 2:
        return css`
          height: 8rem;
          width: 8rem;
          margin: 0.4rem 0;
        `;
    }
  }}
`;
