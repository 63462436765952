import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledTextBoxInput } from '@components/inputs/Inputs.styled';
import { ComponentType } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';

interface FormTextInputProps<T extends FieldValues> {
  control: Control<any>;
  name: Path<T>;
  title: string;
  isMandatory?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  type?: string
}

function FormTextInput<T extends FieldValues>({
  title,
  control,
  name,
  placeholder,
  onChange,
  isMandatory,
  type = "text"
}: FormTextInputProps<T>) {
  const { t } = useTranslation();

  return (
    <div>
      <StyledText type={ComponentType.FormInput}>
        {t(title)}
        {isMandatory ? ' *' : null}
      </StyledText>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <StyledTextBoxInput
            type={type}
            {...field}
            placeholder={placeholder}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) onChange(e.target.value);
            }}
          />
        )}
      />
    </div>
  );
}

export default FormTextInput;
