import { ComponentType, Format } from '@utils/enum.utils';

import styled, { css } from 'styled-components';

export const StylePage = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;

  overflow-y: scroll;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
`;

interface IStyleText {
  fontSize?: string;
  fontWeight?: string;
  margin?: string;
  type?: ComponentType;
  format?: Format;
}

export const StyledText = styled.h1<IStyleText>`
  font-size: ${(props) => props.fontSize ?? "16px"};
  font-weight: ${(props) => props.fontWeight ?? "normal"};
  margin:  ${(props) => props.margin ?? ""};

  ${({ type }) => {
    switch (type) {
      case ComponentType.Button:
        return css`
          color: var(--text-color-button);
          width: max-content;
        `;
      case ComponentType.InfoListText:
        return css`
          font-size: 12px;
          margin: 0;
        `;
      case ComponentType.InfoButtonText:
        return css`
          color: var(--text-color-button);
          font-size: 14px;
        `;
      case ComponentType.InfoListTitle:
        return css`
          font-size: 16px;
          margin: 0.4rem 0;
          font-weight: bolder;
        `;
      case ComponentType.Comment:
        return css`
          color: var(--light-grey-color);
          font-weight: lighter;
          font-size: 13px;
          margin-top: 0;
        `;
      case ComponentType.Waiting:
        return css`
          color: var(--waiting-color);
        `;
      case ComponentType.Canceled:
        return css`
          color: var(--error-todo-color);
        `;
      case ComponentType.Error:
        return css`
          color: var(--error-todo-color);
        `;
      case ComponentType.Validated:
        return css`
          color: var(--validated-color);
        `;
      case ComponentType.Classic:
        return css`
          color: var(--text-color);
        `;
      case ComponentType.FormInput:
        return css`
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 0;
        `;
      case ComponentType.SubTitle:
        return css`
          color: var(--text-color);
          font-size: 24px;
          font-weight: bold;
        `;
      case ComponentType.SubSubTitle:
        return css`
          color: var(--text-color);
          font-size: 24px;
          font-weight: normal;
        `;
      case ComponentType.Title:
        return css`
          color: var(--text-color);
          font-size: 28px;
          font-weight: bold;
        `;
      case ComponentType.BriefAsset:
        return css`
          color: var(--text-color-button);
          font-size: 28px;
          font-weight: bold;
        `;
        case ComponentType.Deadline:
          return css`
            margin-bottom: 0;
            color: var(--text-color);
            text-decoration: underline;
          `;
      default:
        return css`
          color: var(--text-color);
        `;
    }
  }}
`;

export const StyleHeader = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--box-background-color);
  padding: 0rem 1.5rem 1rem ;
`;

interface IStyleSeparator {
  color?: string;
  margin?: string;
  width?: string;
}

export const StyleVerticallySeparator = styled.hr<IStyleSeparator>`
  background-color: ${(props) =>
    props.color ?? "var(--light-grey-color)"};
  size: 1px;
  margin: 0 4px;
  height: 10rem;
`;

export const StyleHorizontallySeparator = styled.hr<IStyleSeparator>`
  color: ${(props) => props.color ?? "var(--text-color)"};
  margin: ${(props) => props.margin ?? ""};
  size: 1px;
  width: ${(props) => props.width ?? "100%"};
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  background-color: var(--box-background-color);
  border-radius: var(--border-radius);
  margin: 1.5rem 1.5rem 0 1.5rem;
  padding: 1rem 1rem 0;
`;

interface IStyleDisplayRow {
  marginright?: string;
  margin?: string;
  justifycontent?: string;
  alignitems?: string;
  border?: boolean;
  padding?: string;
  width?: string;
  gap?: string;
}

export const StyleDisplayRow = styled.div<IStyleDisplayRow>`
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: ${(props) => props.justifycontent ?? ""};
  align-items: ${(props) => props.alignitems ?? "center"};
  padding: ${(props) => props.padding ?? ""};
  width: ${(props) => props.width ?? ""};
  gap: ${(props) => props.gap ?? "0.2rem"};

  ${({ border }) =>
    border &&
    css`
      border: var(--text-color) 1px solid;
      border-radius: var(--border-radius);
    `}
`;

export const StyleDisplayRowEnd = styled.div<IStyleDisplayRow>`
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0 0 0;
  gap: 0.5rem;
  > * {
    margin-right: 0.5%;
  }

  ${({ border }) =>
    border &&
    css`
      border: var(--text-color) 1px solid;
      border-radius: var(--border-radius);
    `}
`;

interface IStyleDisplayColumn {
  margin?: string;
  border?: boolean;
  padding?: string;
  alignItem?: string;
  width?: string;
}

export const StyleDisplayColumn = styled.div<IStyleDisplayColumn>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props) => props.alignItem ?? ""};
  margin: 0.4rem;
  padding: ${(props) => props.padding ?? ""};
  width: ${(props) => props.width ?? "100%"};
  height: 100%;

  ${({ border }) =>
    border &&
    css`
      border: var(--text-color) 3px solid;
      border-radius: 50%;
    `}
`;

export const StyleDisplayColumnSpaceBetween = styled(StyleDisplayColumn)`
  justify-content: space-between;
  height: 100%;
  margin: 0.4rem 0;
  width: 1.5rem;
`;

export const StyleDisplayColumnFlexStart = styled(StyleDisplayColumn)`
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 0.4rem 0;
`;

export const BasicButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  cursor: pointer;
`;

export const StyleTitleBox = styled.div`
  display: flex;
  width: 40%;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyleTitle = styled.h1`
  margin: 1rem 0 0;
`;
