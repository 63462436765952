import styled from 'styled-components';

export const StyledElementsSeasonList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
  gap: 10px;

  > * {
    width: 200px;
    position: relative;
  }
`;
