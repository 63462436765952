import React, { type FC } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import { HomePage, SeasonPage } from '@src/pages';
import { Root, SecureRoute, SecurityContainer } from '@src/routes';
import ProductPage from '@pages/ProductPage';
import { css, Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={css`
      body {
        font-family: 'ITC', sans-serif;
      }
    `}
  />
);

const App: FC = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <SecurityContainer />,
      children: [
        {
          path: '/login/callback',
          element: <LoginCallback />
        },
        {
          path: '/',
          element: <SecureRoute />,
          children: [
            {
              path: '/',
              element: <Root />,
              children: [
                {
                  path: '/',
                  element: <HomePage />
                },
                {
                  path: '/season/:seasonCleanName',
                  element: <SeasonPage />
                },
                {
                  path: '/product/:productId',
                  element: <ProductPage />
                }
              ]
            }
          ]
        }
      ]
    }
  ]);

  return (
    <>
    <GlobalStyles />
      <RouterProvider router={router} />
    </>
  );
};

export default App;
