import { defaultApi } from 'store/api/api';
import endpoints from 'store/api/endpoints';
import {
  METHODS,
  SeasonDetails,
  SeasonDetailsRequest,
  SeasonDetailsResponse,
  SeasonRequest,
  SeasonsOrderByYears,
  SeasonsType,
  TAG_TYPES
} from 'types';

export const seasonApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getSeasons: build.query<
      SeasonsOrderByYears[],
      void
    >({
      query: () => ({
        url: endpoints.season.all,
      }),
      keepUnusedDataFor: 600,
      providesTags: [TAG_TYPES.SEASON],
    }),
    getSeasonsType: build.query<
      SeasonsType[],
      void
    >({
      query: () => ({
        url: endpoints.season.seasonTypes,
      }),
    }),
    getSeason: build.query<
      SeasonDetailsResponse,
      SeasonRequest
    >({
      query: ({ cleanName }) => ({
        url: endpoints.season.withCleanName(cleanName)
      }),
    }),
    postSeason: build.mutation<
      SeasonsOrderByYears[],
      SeasonDetailsRequest
    >({
      query: (season) => {
        return {
          url: endpoints.season.all,
          method: METHODS.POST,
          body: season,
        };
      },
      invalidatesTags: [TAG_TYPES.SEASON],
    }),
    putSeason: build.mutation<
      SeasonDetails,
      SeasonDetails
    >({
      query: (arg) => {
        const { name, ...SeasonDetailsRequest } = arg;
        return {
          url: endpoints.season.withCleanName(name),
          method: METHODS.PUT,
          body: SeasonDetailsRequest,
        };
      },
      invalidatesTags: [TAG_TYPES.SEASON],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSeasonsQuery,
  useLazyGetSeasonsQuery,
  useLazyGetSeasonsTypeQuery,
  useGetSeasonQuery,
  usePostSeasonMutation,
  usePutSeasonMutation
} = seasonApi;
