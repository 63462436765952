import { defaultApi } from 'store/api/api';
import endpoints from 'store/api/endpoints';
import { SubCategoriesDetails, SubCategoriesDetailsRequest, SubCategoryDetailsRequest } from 'types/api/subCategory';

export const subCategoriesApi = defaultApi.injectEndpoints({
  endpoints: (build) => ({
    getSubCategoriesWithCategoryId: build.query<
      SubCategoriesDetails[],
      SubCategoriesDetailsRequest
    >({
      query: ({ category }) => ({
        url: endpoints.subCategory.withCategoryId(category),
      }),
    }),
    getSubCategory: build.query<
      SubCategoriesDetails[],
      SubCategoryDetailsRequest
    >({
      query: ({ id, categoryId }) => ({
        url: endpoints.subCategory.withId(categoryId, id),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetSubCategoriesWithCategoryIdQuery,
} = subCategoriesApi;
