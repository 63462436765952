import { createSlice } from '@reduxjs/toolkit';
import { seasonApi } from '@store/api/season';
import { SeasonDetails, SeasonDetailsResponse, SeasonsOrderByYears, SeasonsType } from '@src/types';

interface SeasonState {
  seasons: SeasonsOrderByYears[];
  seasonsCreated: boolean;
  selectedSeason?: SeasonDetails;
  seasonsType: SeasonsType[];
}

const initialState: SeasonState = {
  seasons: [],
  seasonsCreated: false,
  seasonsType: []
};

const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {
    setSeasons: (state, { payload }: { payload: SeasonsOrderByYears[] }) => {
      state.seasons = payload;
    },
    setSeasonsType: (state, { payload }: { payload: SeasonsType[] }) => {
      state.seasonsType = payload;
    },
    setSelectedSeason: (state, { payload }: { payload: SeasonDetailsResponse }) => {
      state.selectedSeason = payload;
    },
    setSeasonCreated: (state, { payload }: { payload: boolean }) => {
      state.seasonsCreated = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      seasonApi.endpoints.getSeasons.matchFulfilled,
      (state, { payload }) => {
        state.seasons = payload;
      },
    );
    builder.addMatcher(
      seasonApi.endpoints.getSeason.matchFulfilled,
      (state, { payload }) => {
        state.selectedSeason = payload;
      },
    );
    builder.addMatcher(
      seasonApi.endpoints.postSeason.matchFulfilled,
      (state, { payload }) => {
        state.seasons = payload;
        state.seasonsCreated = true;
      },
    );
  },
});

export const {
  setSeasons,
  setSeasonsType,
  setSelectedSeason,
  setSeasonCreated
} = seasonSlice.actions;

export default seasonSlice.reducer;
