import moment from 'moment';
import { AxisDetails } from '@src/types';
import { type CategoriesDetails } from 'types/api/category';
import { type SubCategoriesDetails } from 'types/api/subCategory';

export function getTwoFirstChars(
  str: string,
  position: 'start' | 'end'
): string {
  if (position === 'start') {
    return str.slice(0, 2);
  } else if (position === 'end') {
    return str.slice(-2);
  } else {
    throw new Error("Invalid position. Use 'start' or 'end'.");
  }
}

export function getTenNextYear(): string[] {
  const currentYear = new Date().getFullYear();
  const yearsList: string[] = [];

  for (let i = 1; i < 11; i++) {
    yearsList.push((currentYear + i).toString());
  }
  return yearsList;
}

export function convertTimeZoneInDate(
  hourWithTimeZone: Date | undefined
): string {
  if (hourWithTimeZone) {
    const date = moment(hourWithTimeZone);

    return date.format('DD/MM/YYYY');
  }

  return '';
}

export function toPascalCaseWithSpaces(text: string) {
  const words = text.split(' ');

  const pascalCaseWords = words.map((word) => {
    if (word.trim() === '') return '';
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return pascalCaseWords.join(' ');
}

export function updateNestedField<T extends object, K extends keyof T>(
  obj: T,
  field: K,
  nestedField: keyof T[K],
  value: any
): T {
  return {
    ...obj,
    [field]: {
      ...obj[field],
      [nestedField]: value
    }
  };
}

export const cleanString = (s: string) =>
    s
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+/g, '')
      .replace(/((?=(-+))\2)$/g, '');


export function getNameFromId(id: number, array?: AxisDetails[] | CategoriesDetails[] | SubCategoriesDetails[]): string {
  return array?.find((element) => element.id === id)?.name || "";
}

export function getIdFromName(name: string, array?: AxisDetails[] | CategoriesDetails[] | SubCategoriesDetails[]): number {
  return array?.find((element) => element.name === name)?.id || 0;
}
