import styled, { css } from 'styled-components';

interface IStyledFormContent {
  addProductDialogPage?: number
}

export const StyledForm = styled.form``;

export const StyledFormContent = styled.div<IStyledFormContent>`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  ${({ addProductDialogPage }) => {
    switch (addProductDialogPage) {
      case 1:
        return css`
          width: 15rem;
        `;
      case 2:
        return css`
          width: 30rem;
        `;
      default:
        return css`
          width: 15rem;
        `;
    }
  }}
`;

export const StyledDivInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
`;

export const AddProductDialogContent = styled.form`
  width: 100%;
  padding: 1rem;
`;
