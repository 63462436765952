import { createSlice } from '@reduxjs/toolkit';

type NavigationBarOpenSeason = {
  year: string,
  season: string,
}

interface NavigationBarState {
  selectedTabId?: string;
  openedYears: string[];
  openedSeasons: string[];
}

const initialState: NavigationBarState = {
  openedYears: [],
  openedSeasons: [],
};

const navigationBarSlice = createSlice({
  name: "navigationBar",
  initialState,
  reducers: {
    setSelectedTabId: (state, { payload }: { payload: string }) => {
      state.selectedTabId = payload;
    },
    setOpenedYears: (state, { payload }: { payload: string }) => {
      let newSelectedYears: string[] = [];
      if (state.openedYears.includes(payload)) {
        newSelectedYears = state.openedYears.filter((selectedYear) => selectedYear !== payload);
        const newSelectedSeasons = state.openedSeasons.filter((selectedSeason) => !selectedSeason.includes(payload));
        state.openedSeasons = newSelectedSeasons;
      } else {
        newSelectedYears = [...state.openedYears, payload];
      }
      state.openedYears = newSelectedYears;
    },
    setOpenedSeasons: (state, { payload }: { payload: NavigationBarOpenSeason }) => {
      let newSelectedSeasons: string[] = [];
      const openedSeasonId = `${payload.year}.${payload.season}`;
      if (state.openedSeasons.includes(openedSeasonId)) {
        newSelectedSeasons = state.openedSeasons.filter((selectedSeason) => selectedSeason !== openedSeasonId);
      } else {
        newSelectedSeasons = [...state.openedSeasons, openedSeasonId];
      }
      state.openedSeasons = newSelectedSeasons;
      state.selectedTabId = payload.season;
    },
  },
});

export const {
  setSelectedTabId,
} = navigationBarSlice.actions;

export default navigationBarSlice.reducer;
