import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getProductById } from '@api/business/productBusiness';
import { ProductPageHeader } from '@components/Headers';
import { StylePage } from '@utils/style.utils';
import { ProductDetailsConsolidated } from 'types/api/product';
import { setProductCreated } from '@store/product/product.reducer';
import { useAppDispatch } from '@store/hooks';

function ProductPage() {
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const [product, setProduct] = useState<ProductDetailsConsolidated>();
  const [error, setError] = useState<string>('');
  dispatch(setProductCreated(false));

  const fetchProduct = async() => {
    try {
      if (productId) {
        const productInformations = await getProductById(productId);
        if (productInformations) {
          setProduct(productInformations);
        }
      } else {
        throw Error();
      }
    } catch (error) {
      setError(`Error fetching product: ${error}`);
    }
  };

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  return (
    <StylePage>
      <div>
        {error && <h1>{error}</h1>}
        {product && <ProductPageHeader product={product} />}
      </div>
    </StylePage>
  );
}

export default ProductPage;
