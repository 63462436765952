import React, { useState } from 'react';

import AddProductDialog from '@components/popups/AddProductDialog';
import PopupManager from '@components/popups/PopupManager';
import { Button, Icon } from '@src/components';
import {
  StyleDisplayColumn,
  StyleDisplayColumnFlexStart,
  StyleDisplayRow,
  StyledText,
  StyleHeader,
} from '@src/utils/style.utils';
import { ComponentType, Format } from '@utils/enum.utils';
import { convertTimeZoneInDate } from '@utils/fonctions.utils';
import { useAppSelector } from '@store/hooks';
import { selectedSeason } from '@store/season/season.selector';

interface ISeasonPageHeaderInformations {
  productNumber: number;
  assetNumber: number;
}

function SeasonPageHeader({
  assetNumber,
  productNumber,
}: ISeasonPageHeaderInformations) {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const selectedSeasonState = useAppSelector(selectedSeason);
  // const [editableValues, setEditableValues] = useState(season);

  // const handleFieldChange = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   field: keyof ISeason,
  //   nestedField?: string
  // ) => {
  //   if (nestedField) {
  //     setEditableValues((prevValues) =>
  //       updateNestedField(prevValues, field, nestedField as never, e.target.value)
  //     );
  //   } else {
  //     setEditableValues({
  //       ...editableValues,
  //       [field]: e.target.value
  //     });
  //   }
  // };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  return (
    <StyleHeader>
      <StyleDisplayRow alignitems="flex-start">
        <StyleDisplayColumnFlexStart>
          <StyledText type={ComponentType.Title}>{`${selectedSeasonState?.name}`}</StyledText>
          <StyleDisplayRow>
            <Icon icon="Edit" iconHeight="18" iconWidth="18" />
            <StyledText>
              {`Last update: ${convertTimeZoneInDate(selectedSeasonState?.updateDate)}`}
            </StyledText>
          </StyleDisplayRow>
          <StyleDisplayRow>
            <Icon icon="Pages" iconHeight="18" iconWidth="18" />
            <StyledText> {`Merch Annexes: ${selectedSeasonState?.annexe?.[0] ? selectedSeasonState.annexe[0] : '' }`} </StyledText>
          </StyleDisplayRow>
          <Button
            action={handleOpenPopup}
            text="Add a product"
            icon="Add"
            format={Format.Thin}
          />
          <PopupManager isOpen={isPopupOpen} onClose={handleClosePopup}>
            <AddProductDialog
              closePopup={handleClosePopup}
            />
          </PopupManager>
        </StyleDisplayColumnFlexStart>
        <StyleDisplayColumnFlexStart>
          <StyleDisplayRow border={true} width="100%">
            <StyleDisplayColumn>
              <StyleDisplayRow alignitems="center">
                <Icon icon="Timer" />
                <StyleDisplayColumn>
                  <StyledText type={ComponentType.Deadline}>
                    NON DEF Brief Asset Deadline:
                  </StyledText>
                  <StyledText>
                    {convertTimeZoneInDate(selectedSeasonState?.deadlineNoDef)}
                  </StyledText>
                </StyleDisplayColumn>
              </StyleDisplayRow>
              <StyleDisplayRow alignitems="center">
                <Icon icon="Timer" />
                <StyleDisplayColumn>
                  <StyledText type={ComponentType.Deadline}>
                    DEF Brief Asset Deadline:
                  </StyledText>
                  <StyledText>
                    {convertTimeZoneInDate(selectedSeasonState?.deadlineDef)}
                  </StyledText>
                </StyleDisplayColumn>
              </StyleDisplayRow>
            </StyleDisplayColumn>
            <StyleDisplayColumn width="40%">
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="Icecream" />
                <StyledText> {`${productNumber} products`} </StyledText>
              </StyleDisplayRow>
              <StyleDisplayRow alignitems="center" marginright="3%">
                <Icon icon="WebAsset" />
                <StyledText> {`${assetNumber} assets`} </StyledText>
              </StyleDisplayRow>
            </StyleDisplayColumn>
          </StyleDisplayRow>
        </StyleDisplayColumnFlexStart>
      </StyleDisplayRow>
    </StyleHeader>
  );
}

export default SeasonPageHeader;
