import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledTextBoxInput } from '@components/inputs/Inputs.styled';
import { ComponentType } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';

interface IFormDateInputProps<T extends FieldValues> {
  title: string;
  isMandatory?: boolean;
  control: Control<T>;
  name: Path<T>
}

function FormDateInput<T extends FieldValues>({
  control,
  name,
  title,
  isMandatory
}: IFormDateInputProps<T>) {
  const { t } = useTranslation();

  return (
    <div>
      <StyledText type={ComponentType.FormInput}>
        {t(title)}
        {isMandatory ? '*' : null}
      </StyledText>
      <Controller
        control={control}
        name={name}
        render={({ field }) => <StyledTextBoxInput type="date" {...field} />}
      />
    </div>
  );
}

export default FormDateInput;
