import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ImageComponent from '@components/Image';
import { StyledElementPhoto, StyledProductsInfo } from '@components/ProductElementList/ProductsElementList.styled';
import { Button, ButtonGrouped } from '@src/components';
import { ProductText } from '@utils/constantes.utils';
import { ComponentType } from '@utils/enum.utils';
import { convertTimeZoneInDate } from '@utils/fonctions.utils';
import {
  StyleDisplayColumnFlexStart,
  StyleDisplayRow,
  StyledText,
  StyleHorizontallySeparator
} from '@utils/style.utils';
import { ProductForm, ProductGroupedByLevel, ProductPreviewDetails } from 'types/api/product';
import {
  useDelProductMutation,
  useLazyGetProductQuery,
  useLazyGetProductsWithSeasonQuery,
  usePutProductMutation
} from '@store/api/product';
import { MenuItem } from '@mui/material';
import AddProductDialog from '@components/popups/AddProductDialog';
import PopupManager from '@components/popups/PopupManager';
import { useAppSelector } from '@store/hooks';
import { selectedSeason } from '@store/season/season.selector';

interface IProductsElementListProps {
  product: ProductPreviewDetails
  category: string
  setProducts: React.Dispatch<React.SetStateAction<ProductGroupedByLevel[]>>
}

const ProductsElementList: FC<IProductsElementListProps> = ({ product, category, setProducts }) => {
  const navigation = useNavigate();
  const navigate = () => {
    navigation(`/product/${product.id}`);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [productForm] = useState<ProductForm | undefined>();
  const season = useAppSelector(selectedSeason);
  const [ getProduct ] = useLazyGetProductQuery();
  const [ getProducts, products ] = useLazyGetProductsWithSeasonQuery();
  // const [ createProduct ] = usePostProductMutation();
  const [ updateProduct ] = usePutProductMutation();
  const [ deleteProduct ] = useDelProductMutation();

  // const handleOpenPopup = () => {
  //   setPopupOpen(true);
  // };

  const handleOpenPopupWithGetProduct = () => {
    getProduct({ id: product.id });
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  // TODO manage duplication
  // useEffect(() => {
  //   if (data) {
  //     const product: ProductForm = {
  //       ...data,
  //       seasonId: data.seasonId.toString(),
  //       subCategoryName: data.subCategoryId.toString(),
  //       numberOfSkus: data.numberOfSkus.toString()
  //     }
  //     setProductForm(product);
  //     setPopupOpen(true);
  //   }
  // }, [data]);

  useEffect(() => {
    if (products.data) {
      setProducts(products.data);
    }
  }, [products.data]);

  return (
    <StyleDisplayRow border={true} gap="0.5rem">
      <StyledElementPhoto>
        {product.visual && (
          <ImageComponent url={product.visual[0]} isImgCarousel />
        )}
      </StyledElementPhoto>
      <StyleDisplayColumnFlexStart>
        <StyledText type={ComponentType.InfoListTitle}>
          {product.name}
        </StyledText>
        <StyledProductsInfo>
          <StyledText type={ComponentType.InfoListText}>
            {product.sku + ProductText.SKUs}
          </StyledText>
          { product.additionalPhotos &&
            <>
              <StyledText type={ComponentType.InfoListText}>
                {product.additionalPhotos.length + ProductText.Visuals}
              </StyledText>
            </>
          }
          <StyledText type={ComponentType.InfoListText}>
            {product.numberOfAssets || 0 + ' ' + ProductText.Assets}
          </StyledText>
          <StyledText type={ComponentType.InfoListText}>
            {ProductText.LastUpdate +
              convertTimeZoneInDate(product?.lastUpdate ?? null)}
          </StyledText>
        </StyledProductsInfo>
        <StyleDisplayRow gap="0.5rem">
          <Button
            action={() => {
              navigate();
            }}
            type={ComponentType.InfoButtonText}
            text="VIEW DETAILS"
          />
          <ButtonGrouped>
            <MenuItem
              sx={{ fontSize: '14px', fontFamily: 'ITC' }}
              key="Duplicate"
              onClick={handleOpenPopupWithGetProduct}
            >
              Duplicate the product
            </MenuItem>
            <PopupManager isOpen={isPopupOpen} onClose={handleClosePopup}>
              <AddProductDialog
                closePopup={handleClosePopup}
                initValues={productForm}
                category={category}
              />
            </PopupManager>
            <StyleHorizontallySeparator margin="0" width="99%"/>
            <MenuItem
              sx={{ fontSize: '14px', fontFamily: 'ITC' }}
              key="Cancel"
              onClick={() =>
                updateProduct({
                  ...product,
                  isCancel: true
                })
              }
            >
              Cancel the product
            </MenuItem>
            <StyleHorizontallySeparator margin="0" width="99%"/>
            <MenuItem
              sx={{ fontSize: '14px', fontFamily: 'ITC' }}
              key="Delete"
              onClick={() => {
                deleteProduct({
                  id: product.id
                })
                getProducts({ cleanName: season?.cleanName })
              }
              }
            >
              Delete the product
            </MenuItem>
          </ButtonGrouped>
        </StyleDisplayRow>
      </StyleDisplayColumnFlexStart>
    </StyleDisplayRow>
  );
}

export default ProductsElementList;
