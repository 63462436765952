export type Credentials = {
  accessToken: string;
  idToken: string;
  apiKey: string;
};

export enum METHODS {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

