import { createProductDA, getProductByIdDA, getProductBySeasonIdDA } from '@api/dataAccess/productDataAccess';
import { IProduct, IProductsByLevel, IProductSpecificities } from '@models/api/products';
import { ProductDetailsConsolidated } from 'types/api/product';

export async function getProductBySeasonId(
  seasonId: string
): Promise<IProductsByLevel[]> {
  try {
    const products = await getProductBySeasonIdDA(seasonId);
    return products != null ? products : [];
  } catch (error) {
    throw Error('An error occurred');
  }
}

export async function getProductById(id: string): Promise<ProductDetailsConsolidated | null> {
  try {
    return await getProductByIdDA(id);
  } catch (error) {
    throw Error('An error occurred');
  }
}

export async function createProduct(
  data: IProductSpecificities
): Promise<IProduct | null> {
  try {
    return await createProductDA(data);
  } catch (error) {
    throw Error('An error occurred');
  }
}
