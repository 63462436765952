import React, { useEffect, useState } from 'react';

import {
  EditableField,
  EditableTitle,
  HeaderBorderedItem,
  HeaderColumn,
  HeaderDetailsColumn,
  HeaderDetailsLine,
  HeaderImage,
  HeaderImageColumn,
  HeaderItem,
  HeaderItemLabel,
  HeaderItemLabelRight,
  HeaderItemLabelRightBold,
  HeaderItemRightAlign,
  HeaderRow,
} from '@components/Headers/Header.styled';
import { Button } from '@src/components';
import { Format } from '@utils/enum.utils';
import { updateNestedField } from '@utils/fonctions.utils';
import { StyleHeader } from '@utils/style.utils';
import { ProductDetailsConsolidated } from 'types/api/product';
import { useAppSelector } from '@store/hooks';
import { selectSeasons } from '@store/season/season.selector';
import { usePutProductMutation } from '@store/api/product';
import { selectCategoryType } from '@store/category/category.selector';
import { CategoriesDetails } from 'types/api/category';
import { SubCategoriesDetails } from 'types/api/subCategory';
import { SeasonsGlobalInformations } from '@src/types';

interface ProductHeaderProps {
  product: ProductDetailsConsolidated;
}

function ProductPageHeader({ product }: ProductHeaderProps) {
  const [editableValues, setEditableValues] = useState<ProductDetailsConsolidated>(product);
  const seasons = useAppSelector(selectSeasons);
  const [updateProduct, { data }] = usePutProductMutation();
  const categories = useAppSelector(selectCategoryType);
  const [season, setSeason] = useState<SeasonsGlobalInformations>();
  const [category, setCategory] = useState<CategoriesDetails | undefined>(undefined);
  const [subCategory, setSubCategory] = useState<SubCategoriesDetails | undefined>(undefined);
  const editable = true;

  useEffect(() => {
    categories.map((categoryItem) => {
      const goodSubCategoryItem = categoryItem.subCategories.find((subCategoryItem) => subCategoryItem.id === product.subCategoryId);
      if (goodSubCategoryItem) {
        setCategory(categoryItem);
        setSubCategory(goodSubCategoryItem);
      }
    });
  }, []);

  useEffect(() => {
    seasons.map((yearItem) => {
      yearItem.seasons.map((seasonItem) => {
        const goodSeasonItem = seasonItem.elements.find((element) => element.id === product.seasonId);
        if (goodSeasonItem) {
          setSeason(goodSeasonItem);
        }
      });
    });
  }, []);

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof ProductDetailsConsolidated,
    nestedField?: string,
  ) => {
    if (nestedField) {
      setEditableValues((prevValues) =>
        updateNestedField(
          prevValues,
          field,
          nestedField as never,
          e.target.value,
        ),
      );
    } else {
      setEditableValues({
        ...editableValues,
        [field]: e.target.value,
      });
    }
  };

  const handleOnBlur = (
  ) => {
    updateProduct(editableValues);
    if (data) {
      setEditableValues(data);
    }
  };

  return (
    <StyleHeader>
      <HeaderRow>
        <HeaderImageColumn>
          <HeaderImage src="" alt={product.name} />
          <HeaderDetailsColumn>
            <EditableTitle
              type="text"
              value={editableValues.name}
              onChange={(e) => {
                handleFieldChange(e, "name");
              }}
              onBlur={handleOnBlur}
              disabled={editable}
            />
            <HeaderDetailsColumn margin="20px 20px 20px 0">
              <HeaderDetailsLine>
                <HeaderItem width="200px">
                  <HeaderItemLabel>Assortment File:</HeaderItemLabel>
                  <EditableField type="text" disabled={editable} />
                </HeaderItem>
                <HeaderItem width="200px">
                  <HeaderItemLabel>Additional Photos:</HeaderItemLabel>
                  <EditableField type="text" disabled={editable} />
                </HeaderItem>
              </HeaderDetailsLine>
              <HeaderDetailsLine>
                <HeaderItem width="200px">
                  <HeaderItemLabel>Number of SKUs:</HeaderItemLabel>
                  <EditableField
                    type="number"
                    value={editableValues.numberOfSkus}
                    onChange={(e) => {
                      handleFieldChange(e, "numberOfSkus");
                    }}
                    disabled={editable}
                  />
                </HeaderItem>
                <HeaderItem width="200px">
                  <HeaderItemLabel>Sell Out Ambitions:</HeaderItemLabel>
                  <EditableField
                    type="text"
                    value={editableValues.sellOutAmbition}
                    onChange={(e) => {
                      handleFieldChange(e, "sellOutAmbition");
                    }}
                    disabled={editable}
                  />
                </HeaderItem>
              </HeaderDetailsLine>
            </HeaderDetailsColumn>
          </HeaderDetailsColumn>
        </HeaderImageColumn>
        <HeaderColumn>
          <Button action={() => {}} format={Format.Icon} text="..." />
          <HeaderItemLabelRight>
            {season?.name}
          </HeaderItemLabelRight>
          <HeaderDetailsLine>
            <HeaderItemRightAlign>
              <HeaderItemLabelRightBold>
                {category?.name === subCategory?.name ? category?.name : `${category?.name} - ${subCategory?.name}`}
              </HeaderItemLabelRightBold>
            </HeaderItemRightAlign>
          </HeaderDetailsLine>
          <HeaderItemRightAlign>
            <HeaderItemLabelRightBold>
              {editableValues.level}
            </HeaderItemLabelRightBold>
          </HeaderItemRightAlign>
          <HeaderItemRightAlign>
            <HeaderItemLabelRightBold>
              {editableValues.status}
            </HeaderItemLabelRightBold>
          </HeaderItemRightAlign>
        </HeaderColumn>
      </HeaderRow>
      <HeaderItem>
        <HeaderItemLabel>
          Key elements to highlight in visuals:
        </HeaderItemLabel>
        <HeaderBorderedItem>
          <EditableField
            type="text"
            value={editableValues.elementToHighlight}
            onChange={(e) => {
              handleFieldChange(e, "elementToHighlight");
            }}
            disabled={editable}
          />
        </HeaderBorderedItem>
      </HeaderItem>
      <HeaderRow>
        <HeaderDetailsColumn margin="0" height="4rem">
          <HeaderDetailsLine>
            <HeaderItemLabel>MOP owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.mopOwner}
              onChange={(e) => {
                handleFieldChange(e, "mopOwner");
              }}
              disabled={editable}
            />
          </HeaderDetailsLine>
          <HeaderDetailsLine>
            <HeaderItemLabel>Digital owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.mopOwner}
              onChange={(e) => {
                handleFieldChange(e, "mopOwner");
              }}
              disabled={editable}
            />
          </HeaderDetailsLine>
          <HeaderDetailsLine>
            <HeaderItemLabel>Marketing Product owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.productOwner}
              onChange={(e) => {
                handleFieldChange(e, "productOwner");
              }}
              disabled={editable}
            />
          </HeaderDetailsLine>
        </HeaderDetailsColumn>
        <HeaderDetailsColumn margin="0" height="4rem">
          <HeaderDetailsLine>
            <HeaderItemLabel>Model shot image owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.mopOwner}
              onChange={(e) => {
                handleFieldChange(e, "mopOwner");
              }}
              disabled={editable}
            />
          </HeaderDetailsLine>
          <HeaderDetailsLine>
            <HeaderItemLabel>product image owner: </HeaderItemLabel>
            <EditableField
              type="text"
              value={editableValues.mopOwner}
              onChange={(e) => {
                handleFieldChange(e, "mopOwner");
              }}
              disabled={editable}
            />
          </HeaderDetailsLine>
        </HeaderDetailsColumn>
      </HeaderRow>
    </StyleHeader>
  );
}

export default ProductPageHeader;
