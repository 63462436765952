import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddSeasonDialog from '@components/popups/AddSeasonDialog';
import PopupManager from '@components/popups/PopupManager';
import { LanguageKey } from '@languages/enum';
import LogoSephoraCollection from '@resources/images/LogoSephoraCollection';
import { Button, SeasonsList } from '@src/components';
import { IconName } from '@utils/data/enums/icons.enum';
import { Format } from '@utils/enum.utils';
import { StyleHeader, StylePage, StyleTitleBox } from '@utils/style.utils';

function HomePage() {
  const { t } = useTranslation();
  const [isPopupOpen, setPopupOpen] = useState(false);

  const handlePopup = (isOpen: boolean) => {
    setPopupOpen(isOpen);
  };

  return (
    <StylePage>
      <StyleHeader>
        <StyleTitleBox>
          <LogoSephoraCollection />
        </StyleTitleBox>
        <Button
          action={() => {
            handlePopup(true);
          }}
          text={t(LanguageKey.AddNewSeason)}
          icon={IconName.ADD}
          format={Format.Thin}
        />
      </StyleHeader>
        <div>
          <SeasonsList />
          <PopupManager
            isOpen={isPopupOpen}
            onClose={() => {
              handlePopup(false);
            }}
          >
            <AddSeasonDialog
              isOpen={isPopupOpen}
              closePopup={() => {
                handlePopup(false);
              }}
            />
          </PopupManager>
        </div>
    </StylePage>
  );
}

export default HomePage;
