import React from 'react';

import { StyledBtnUpload, StyledImgUpload } from '@components/UploadZone/UploadZone.styled';
import { StyleUploadZoneInput } from '@components/inputs/Inputs.styled';
import { Button } from '@src/components';
import { ProductText } from '@utils/constantes.utils';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyleDisplayColumn, StyledText } from '@utils/style.utils';

import { t } from 'i18next';

interface IUploadZoneProps {
  addProductDialogPage?: number;
  carrouselDisplay?: boolean;
  highlighted?: boolean;
  imageSrc: string[];
  type: string;
  setHighlighted: React.Dispatch<React.SetStateAction<boolean>>;
  setCarrouselImageDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setImageSrc: React.Dispatch<React.SetStateAction<string[]>>
}

function UploadZone({
  addProductDialogPage,
  carrouselDisplay,
  highlighted,
  imageSrc,
  type,
  setHighlighted,
  setCarrouselImageDisplay,
  setImageSrc
}: IUploadZoneProps) {
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file: File) => {
    if (file.type.startsWith('image/')) {
      setCarrouselImageDisplay(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSrc = e.target?.result as string;
        setImageSrc([...imageSrc, newImageSrc]);
      };
      reader.readAsDataURL(file);
    } else {
      alert(ProductText.AlertUpload);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files?.[0];
    if (files) {
      handleFile(files);
    }
  };

  const handleUpload = () => {
    document.getElementById(ProductText.ImageInput)?.click();
  };

  return (
    <StyledImgUpload
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDragOver={highlighted}
      onChange={handleFileInputChange}
      isDisplay={carrouselDisplay}
      addProductDialogPage={addProductDialogPage}
    >
      <StyleUploadZoneInput
        type={ComponentType.File}
        id={ProductText.ImageInput}
        onChange={handleFileInputChange}
        accept={ProductText.Image}
        hidden
      />
      <StyleDisplayColumn>
        <StyledBtnUpload>
          <Button
            action={handleUpload}
            type={ComponentType.Button}
            format={Format.Thin}
            text={t(ProductText.Upload)}
          />
        </StyledBtnUpload>
        <StyledText>{type}</StyledText>
      </StyleDisplayColumn>
    </StyledImgUpload>
  );
}

export default UploadZone;
