import { StyledPopupContent } from '@components/popups/PopupManager/PopupManager.styled';

import styled from 'styled-components';

export const ExtendedStyledPopupContent = styled(StyledPopupContent)`
  width: 30rem;
`;

export const AddSeasonDialogStyled = styled.div`
  width: 100%;
`;
export const AddSeasonDialogContentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 20px;
  justify-items: center;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;
