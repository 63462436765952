import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { setCredentials } from '@store/auth/auth.reducer';
import { useOktaAuth } from '@okta/okta-react';
import { useAppDispatch } from '@store/hooks';
import { getCredentials } from '@utils/common.utils';
import { toRelativeUrl } from '@okta/okta-auth-js';

const SecureRoute = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      if (!authState) {
        return;
      }

      const credentials = getCredentials(authState);

      if (!authState?.isAuthenticated) {
        const originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin,
        );
        oktaAuth.setOriginalUri(originalUri);
        await oktaAuth.signInWithRedirect();
      } else if (credentials) {
        dispatch(setCredentials(credentials));
      }
    })();
  }, [authState?.isAuthenticated, authState?.accessToken]);

  return <Outlet />;
};

export default SecureRoute;
