import React, { useState } from 'react';
import {
  StyleDivButtonSeason,
  StyleDownloadBtn,
  StyleLockOrEdit,
} from '@components/OverloadSeason/OverloadSeason.styled';
import * as Icons from '@mui/icons-material';
import { Button, Icon } from '@src/components';
import { IconName } from '@utils/data/enums/icons.enum';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyleDisplayColumnSpaceBetween } from '@utils/style.utils';
import { SeasonsGlobalInformations } from '@src/types';

interface IIconButtonProps {
  action: () => void;
  element: SeasonsGlobalInformations;
}

function OverloadSeason({ action, element }: IIconButtonProps) {
  const [icon, setIcon] = useState<keyof typeof Icons>(IconName.EDIT);
  const [type, setType] = useState(ComponentType.Open);

  if (element.isClosed) {
    setType(ComponentType.Blocked);
    setIcon(IconName.LOCK);
  }

  //TODO function to create ppt and download it
  const handleDownloadBrief = () => {};

  return (
    <div>
      <StyleDivButtonSeason>
        <Button
          key={element.id}
          action={action}
          text={element.name}
          format={Format.Large}
        />
        <StyleDisplayColumnSpaceBetween>
          <StyleLockOrEdit>
            <Icon
              icon={icon}
              type={type}
              border={true}
              iconWidth="0.4em"
              iconHeight="0.4em"
              iconColor="white"
            />
          </StyleLockOrEdit>
          <StyleDownloadBtn onClick={handleDownloadBrief}>
            <Icon
              icon={IconName.DOWNLOAD}
              type={type}
              iconWidth="0.5em"
              iconHeight="0.5em"
              iconColor="white"
            />
          </StyleDownloadBtn>
        </StyleDisplayColumnSpaceBetween>
      </StyleDivButtonSeason>
    </div>
  );
}

export default OverloadSeason;
