import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormDateInput from '@components/inputs/FormDateInput';
import FormSelectInput from '@components/inputs/FormSelectInput';
import FormTextInput from '@components/inputs/FormTextInput';
import {
  AddSeasonDialogContentStyled,
  AddSeasonDialogStyled,
  ExtendedStyledPopupContent,
} from '@components/popups/AddSeasonDialog/AddSeasonDialog.styled';
import { StyledPopupHeader, StyledSubmitButton, } from '@components/popups/PopupManager/PopupManager.styled';
import { LanguageKey } from '@languages/enum';
import { Button } from '@src/components';
import { NewSeason } from '@utils/constantes.utils';
import { IconName } from '@utils/data/enums/icons.enum';
import { ComponentType, Format } from '@utils/enum.utils';
import { cleanString, getIdFromName, getTenNextYear, getTwoFirstChars } from '@utils/fonctions.utils';
import { BasicButton, StyledText, StyleVerticallySeparator, } from '@utils/style.utils';
import { AxisDetails, SEASON_TYPES, SeasonDetailsRequest, SeasonForm } from '@src/types';
import moment from 'moment';
import { useAppSelector } from '@store/hooks';
import { seasonsCreated, selectSeasonsType } from '@store/season/season.selector';
import { usePostSeasonMutation } from '@store/api/season';
import { generatePath, useNavigate } from 'react-router-dom';
import { useLazyGetAxisWithSeasonQuery } from '@store/api/axis';

interface AddSeasonDialogProps {
  closePopup: () => void;
  isOpen: boolean;
}

function AddSeasonDialog({
  closePopup,
  isOpen,
}: AddSeasonDialogProps): JSX.Element {
  const { t } = useTranslation();
  const years = getTenNextYear();
  const [isUpdatedName, setIsUpdatedName] = useState(false);
  const [showError] = useState(false);
  const [axisList, setAxisList] = useState<AxisDetails[]>([])
  const popupRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const seasonTypes = useAppSelector(selectSeasonsType);
  const [fetchAxis] = useLazyGetAxisWithSeasonQuery();
  const [postSeason] = usePostSeasonMutation();

  const seasonsCreatedState = useAppSelector(seasonsCreated);

  const { control, handleSubmit, watch, setValue } = useForm<SeasonForm>({
    defaultValues: {
      year: "",
      seasonName: "",
      axisName: "",
      name: "",
      deadlineDef: moment().toDate(),
      deadlineNoDef: moment().toDate(),
    },
  });

  const year = watch("year");
  const seasonName = watch("seasonName");
  const axisName = watch("axisName");
  const name = watch("name");

  const seasonalBriefName = () => {
    switch (seasonName) {
    case SEASON_TYPES.HOLIDAY: {
      return `${getTwoFirstChars(seasonName, 'start')}${getTwoFirstChars(year, 'end')}`;
    }
    case SEASON_TYPES.MEDIA_ALWAYS_ON: {
      return `${seasonName.toUpperCase()}${getTwoFirstChars(year, 'end')}`;
    }
    case SEASON_TYPES.SPRING:
    case SEASON_TYPES.SUMMER :
    case SEASON_TYPES.FALL:
    default: {
        return `${getTwoFirstChars(seasonName, 'start')}${getTwoFirstChars(year, 'end')} ${axisName}`;
      }
    }
  }


  useEffect(() => {
    if (!isUpdatedName) {
      setValue("name", seasonalBriefName());
    }
  }, [year, seasonName, axisName, isUpdatedName, setValue]);

  useEffect(() => {
      (async function fetch() {
        const seasonType = getIdFromName(seasonName, seasonTypes);
        const { data } = await fetchAxis({ seasonType });
        if (data) {
          setAxisList(data);
          if (data.length === 1) {
            setValue("axisName", data[0].name);
          }
        }
      }());
  }, [seasonName]);

  useEffect(() => {
    if (seasonsCreatedState) {
      const redirectWithParams = generatePath(
        "season/:cleanName",
        { cleanName: cleanString(name) || null },
      );
      navigate(redirectWithParams);
    }
  }, [seasonsCreatedState]);

  const giveAName = (value: string) => {
    setValue("name", value);
    setIsUpdatedName(true);
  };

  const handleAddSeason = async (data: SeasonForm) => {
    const seasonData: SeasonDetailsRequest = {
      name:
        data.name === "" || data.name.trim().length === 0
          ? seasonName
          : data.name,
      year: data.year,
      deadlineDef: data.deadlineDef,
      deadlineNoDef: data.deadlineNoDef,
      seasonTypeId: getIdFromName(data.seasonName, seasonTypes),
      axisId: getIdFromName(data.axisName, axisList)
    };
    postSeason(seasonData);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
      closePopup();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <ExtendedStyledPopupContent ref={popupRef}>
      <AddSeasonDialogStyled>
        <StyledPopupHeader>
          <Button
            action={closePopup}
            icon={IconName.CROSS}
            format={Format.Classic}
            type={ComponentType.Classic}
          />
        </StyledPopupHeader>
        <h2>{t(NewSeason)}</h2>
        {showError && (
          <StyledText type={ComponentType.Canceled}>
            {t(LanguageKey.CreateSeasonError)}
          </StyledText>
        )}
        <form onSubmit={handleSubmit(handleAddSeason)}>
          <AddSeasonDialogContentStyled>
            <div>
              <FormSelectInput
                title={LanguageKey.Season}
                control={control}
                isMandatory
                name="seasonName"
                options={seasonTypes.map((season) => {
                  return { value: season.name, label: season.name }
                })}
              />
              <FormSelectInput
                title={LanguageKey.Year}
                control={control}
                name="year"
                isMandatory
                options={years.map((year) => ({ value: year, label: year }))}
              />
              {axisList.length > 1 && <FormSelectInput
                title={LanguageKey.Axis}
                control={control}
                isMandatory
                name="axisName"
                options={axisList.map((axisTypeState) => {
                  return {
                    value: axisTypeState.name,
                    label: t(axisTypeState.name),
                  }
                })}
              />}
              <FormTextInput
                title={LanguageKey.Name}
                control={control}
                name="name"
                isMandatory
                onChange={giveAName}
              />
            </div>

            <StyleVerticallySeparator />

            <div>
              <FormDateInput
                title={LanguageKey.DeadlineNoDef}
                isMandatory
                control={control}
                name="deadlineNoDef"
              />
              <FormDateInput
                title={LanguageKey.DeadlineDef}
                isMandatory
                control={control}
                name="deadlineDef"
              />
            </div>
          </AddSeasonDialogContentStyled>
          <StyledSubmitButton>
            <BasicButton type="submit">
              <Button
                action={() => {}}
                format={Format.Thin}
                text={t(LanguageKey.AddSeason)}
              />
            </BasicButton>
          </StyledSubmitButton>
        </form>
      </AddSeasonDialogStyled>
    </ExtendedStyledPopupContent>
  );
}

export default AddSeasonDialog;
