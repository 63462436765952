import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';

import i18n from '@languages/i18n';
import App from '@src/App';
import '@src/index.css';
import { Provider } from 'react-redux';
import store from '@store/store';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <App />
        </div>
      </I18nextProvider>
    </React.StrictMode>
  </Provider>,
);
