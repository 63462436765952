import axios from 'axios';
import { API_URL, } from 'utils/env.utils';

const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const apikey = localStorage.getItem('apiKey');
    const accessToken = localStorage.getItem('accessToken');
    const idToken = localStorage.getItem('idToken');
    if (accessToken && idToken) {
      config.headers['x-api-key'] = apikey;
      config.headers['x-access-token'] = accessToken;
      config.headers['x-id-token'] = idToken;
    }
    return config;
  },
  async(error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
