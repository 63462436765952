import { IProduct, IProductsByLevel, IProductSpecificities } from '@models/api/products';
import axiosInstance from '@utils/axios.utils';
import { ProductDetailsConsolidated } from 'types/api/product';

export async function getProductBySeasonIdDA(
  seasonId: string
): Promise<IProductsByLevel[] | null> {
  try {
    const response = await axiosInstance.get(`products?season=${seasonId}`);
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
}

export async function getProductByIdDA(id: string): Promise<ProductDetailsConsolidated | null> {
  try {
    const response = await axiosInstance.get(`products/${id}`);
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
}

export async function createProductDA(
  data: IProductSpecificities
): Promise<IProduct | null> {
  try {
    const response = await axiosInstance.post('products/', data);
    return response.data;
  } catch (error: any) {
    throw Error(error);
  }
}
