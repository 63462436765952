import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SeasonPageHeader } from '@components/Headers';
import ProductsList from '@components/ProductsList/ProductsList';
import { StylePage } from '@utils/style.utils';
import { useGetSeasonQuery, useGetSeasonsQuery } from '@store/api/season';
import { useGetAxisWithSeasonQuery } from '@store/api/axis';
import { useGetProductsWithSeasonQuery } from '@store/api/product';
import { ProductGroupedByLevel } from 'types/api/product';
import { useAppDispatch } from '@store/hooks';
import { setSeasonCreated } from '@store/season/season.reducer';

function SeasonPage() {
  const { seasonCleanName } = useParams();
  const dispatch = useAppDispatch();
  dispatch(setSeasonCreated(false));
  const [productsGroupedByLevel, setProductsGroupedByLevel] = useState<ProductGroupedByLevel[]>([]);
  const [assetCount] = useState<number>(0);
  useGetSeasonsQuery();
  const season = useGetSeasonQuery({ cleanName: seasonCleanName }).data;
  useGetAxisWithSeasonQuery({ seasonType: season?.seasonTypeId });
  const productsList = useGetProductsWithSeasonQuery({ cleanName: seasonCleanName}).data

  useEffect(() => {
    if (productsList) {
      setProductsGroupedByLevel(productsList);
    }
  }, [productsList]);

  //TODO get the assetNumber
  return (
    <StylePage>
        <div>
          <SeasonPageHeader
            productNumber={productsGroupedByLevel?.length ?? 0}
            assetNumber={assetCount}
          />
          {productsGroupedByLevel ? <ProductsList products={productsGroupedByLevel} setProducts={setProductsGroupedByLevel} /> : <></>}
        </div>
    </StylePage>
  );
}

export default SeasonPage;
