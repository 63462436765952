import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth from 'store/auth/auth.reducer';
import seasonReducer from '@store/season/season.reducer';
import middlewares from '@store/middlewares';
import { defaultApi } from '@store/api/api';
import navigationBarReducer from '@store/navigationbar/navigationBar.reducer';
import axisReducer from '@store/axis/axis.reducer';
import productReducer from '@store/product/product.reducer';
import categoryReducer from '@store/category/category.reducer';

const persistConfig = {
  key: "redux",
  storage,
  whitelist: ["auth"],
};
const reducer = combineReducers({
  auth: persistReducer(persistConfig, auth),
  [defaultApi.reducerPath]: defaultApi.reducer,
  season: seasonReducer,
  navigationBar: navigationBarReducer,
  axis: axisReducer,
  product: productReducer,
  category: categoryReducer,
});
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof reducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
