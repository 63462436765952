import { createSlice } from '@reduxjs/toolkit';
import { productApi } from '@store/api/product';

interface ProductState {
  productCreated: boolean;
  productId?: number
}

const initialState: ProductState = {
  productCreated: false,
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductCreated: (state, { payload }: { payload: boolean }) => {
      state.productCreated = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productApi.endpoints.postProduct.matchFulfilled,
      (state, { payload }) => {
        state.productId = payload.id;
        state.productCreated = true;
      },
    );
  }
});

export const {
  setProductCreated
} = productSlice.actions;

export default productSlice.reducer;
