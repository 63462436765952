import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { StyledIconArrow } from '@components/Icon/Icon.styled';
import {
  StyledContentListNavigationBar,
  StyledContentNavigationBar,
  StyledIconNavigationBar,
  StyledNavigationBar,
  StyledTitleNavigationBar,
} from '@components/NavigationBar/NavigationBar.styled';
import { LanguageKey } from '@languages/enum';
import { Icon } from '@src/components';
import { Home, SeasonalBrief } from '@utils/constantes.utils';
import { IconName } from '@utils/data/enums/icons.enum';
import { ComponentType } from '@utils/enum.utils';
import capitalizeFirstLetter from '@utils/format.utils';
import LogoSephoraCollection from '@resources/images/LogoSephoraCollection';
import { setSelectedTabId } from '@store/navigationbar/navigationBar.reducer';
import { navigationBar } from '@store/navigationbar/navigationBar.selector';
import { useAppDispatch, useAppSelector } from '@store/hooks';
import { getNameFromId } from '@utils/fonctions.utils';
import { selectSeasons, selectSeasonsType } from '@store/season/season.selector';

interface NavItemProps {
  icon?: IconName;
  label: string;
  onClick?: () => void;
  isCategory?: boolean;
  isSubCategory?: boolean;
  show?: boolean;
  rotated?: boolean;
  isLogOut?: boolean;
  clickable?: boolean;
  clicked?: boolean;
  depth?: number;
  children?: React.ReactNode;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  label,
  onClick,
  isCategory = false,
  isSubCategory = false,
  show = true,
  rotated = false,
  isLogOut = false,
  clickable = true,
  clicked = false,
  depth,
  children,
}) => (
  <div>
    <StyledContentNavigationBar
      depth={depth}
      show={show}
      onClick={onClick}
      isLogOut={isLogOut}
      clickable={clickable}
      clicked={clicked}
    >
      {(isCategory || isSubCategory) && (
        <StyledIconArrow rotated={rotated}>
          <Icon
            icon={IconName.NAV_ARROW_RIGHT}
            type={ComponentType.Arrow}
            iconWidth={isCategory ? "0.7em" : "0.5em"}
            iconHeight={isCategory ? "0.7em" : "0.5em"}
          />
        </StyledIconArrow>
      )}
      {!isCategory && !isSubCategory && icon && (
        <StyledIconNavigationBar>
          <Icon icon={icon} type={ComponentType.Arrow} />
        </StyledIconNavigationBar>
      )}
      {label}
    </StyledContentNavigationBar>
    {children}
  </div>
);

const NavigationBar: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openedYears, setOpenedYears] = useState<string[]>([]);
  const [openedSeasons, setOpenedSeasons] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const saisons = useAppSelector(selectSeasons);
  const navigationBarState = useAppSelector(navigationBar);
  const saisonType = useAppSelector(selectSeasonsType);

  const handleYearClick = (year: string) => {
    let newSelectedYears: string[] = [];
    if (openedYears.includes(year)) {
      newSelectedYears = openedYears.filter((selectedYear) => selectedYear !== year);
      const newSelectedSeasons = openedSeasons.filter((selectedSeason) => !selectedSeason.includes(year));
      setOpenedSeasons(newSelectedSeasons);
    } else {
      newSelectedYears = [...openedYears, year];
    }
    setOpenedYears(newSelectedYears);
  };

  const handleSeasonClick = (year:string, seasonId: number) => {
    let newSelectedSeasons: string[] = [];
    if (openedSeasons.includes(`${year}.${seasonId}`)) {
      newSelectedSeasons = openedSeasons.filter((selectedSeason) => selectedSeason !== `${year}.${seasonId}`);
    } else {
      newSelectedSeasons = [...openedSeasons, `${year}.${seasonId}`];
    }
    setOpenedSeasons(newSelectedSeasons);
  };

  return (
    <StyledNavigationBar>
      <StyledTitleNavigationBar>
        <LogoSephoraCollection />
      </StyledTitleNavigationBar>
      <StyledContentListNavigationBar>
        <NavItem
          icon={IconName.HOME}
          label={Home}
          clicked={navigationBarState.selectedTabId === "home"}
          onClick={() => {
            dispatch(setSelectedTabId("home"));
            navigate("/");
          }}
        />
        <NavItem icon={IconName.SUN} label={SeasonalBrief} clickable={false}/>
        {saisons.length > 1 && saisons.map((item) => (
              <React.Fragment key={item.year}>
                <NavItem
                  label={item.year}
                  onClick={() => {
                    handleYearClick(item.year);
                  }}
                  isCategory
                  rotated={openedYears.includes(item.year)}
                  depth={1}
                >
                  {item.seasons.map((seasonGroup) => (
                    <NavItem
                      key={seasonGroup.seasonId}
                      label={capitalizeFirstLetter(getNameFromId(seasonGroup.seasonId, saisonType))}
                      onClick={() => {
                        handleSeasonClick(item.year,seasonGroup.seasonId);
                      }}
                      isSubCategory
                      show={openedYears.includes(item.year)}
                      rotated={openedSeasons.includes(`${item.year}.${seasonGroup.seasonId}`)}
                      depth={2}
                    >
                      {seasonGroup.elements.map((element) => (
                        <NavItem
                          depth={3}
                          key={element.id}
                          label={element.name}
                          show={
                            openedYears.includes(item.year) &&
                            openedSeasons.includes(`${item.year}.${seasonGroup.seasonId}`)
                          }
                          clicked={navigationBarState.selectedTabId === element.cleanName}
                          onClick={() => {
                            dispatch(setSelectedTabId(element.cleanName));
                            const redirectWithParams = generatePath(
                              "season/:cleanName",
                              { cleanName: element.cleanName },
                            );
                            navigate(redirectWithParams);
                          }}
                        />
                      ))}
                    </NavItem>
                  ))}
                </NavItem>
              </React.Fragment>
            ))}
        <NavItem
          icon={IconName.LOGOUT}
          label={t(LanguageKey.LogOut)}
          isLogOut
          onClick={() => {
            /* Log out functionality */
          }}
        />
      </StyledContentListNavigationBar>
    </StyledNavigationBar>
  );
};

export default NavigationBar;
