import { createSlice } from '@reduxjs/toolkit';
import { type Credentials } from 'types';

export interface UserState {
  credentials?: Credentials;
}

const initialState: UserState = {
  credentials: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, { payload }: { payload: Credentials }) => {
      state.credentials = payload;
    },
    removeCredentials: (state) => {
      state.credentials = undefined;
    },
  },
});

export const {
  setCredentials,
  removeCredentials,
} = authSlice.actions;

export default authSlice.reducer;
