import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StyledSelectInput } from '@components/inputs/Inputs.styled';
import { ComponentType } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';

interface IElementValue {
  value: string | number;
  label: string
}

interface IFormSelectInputProps<T extends FieldValues> {
  title: string;
  isMandatory?: boolean;
  control: Control<T>;
  name: Path<T>;
  options: IElementValue[];
}

function FormSelectInput<T extends FieldValues>({
  title,
  control,
  name,
  options,
  isMandatory
}: IFormSelectInputProps<T>) {
  const { t } = useTranslation();
  return (
    <div>
      <StyledText type={ComponentType.FormInput}>
        {t(title)}
        {isMandatory ? ' *' : null}
      </StyledText>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <StyledSelectInput {...field}>
            <option value="">Select option</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </StyledSelectInput>
        )}
      />
    </div>
  );
}

export default FormSelectInput;
