import React, { useState } from 'react';

import { StyledDeleteImageButtonBox } from '@components/Button/Button.styled';
import {
  StyledBtnAddImg,
  StyledBtnArrowCarousel,
  StyledBtnUploadSide,
  StyledImgUploadCarousel,
  StyledImgUploadCarouselContent,
  StyledTransitionImgCarousel
} from '@components/Carousel/CarouselPhotos.styled';
import ImageComponent from '@components/Image';
import { Button } from '@src/components';
import { CarrouselText } from '@utils/constantes.utils';
import { IconName } from '@utils/data/enums/icons.enum';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyleDisplayRow } from '@utils/style.utils';

interface ICarouselProps {
  addProductDialogPage?: number;
  carrouselDisplay?: boolean;
  highlighted?: boolean;
  imageSrc: string[];
  setHighlighted: React.Dispatch<React.SetStateAction<boolean>>;
  setCarrouselImageDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  setImageSrc: React.Dispatch<React.SetStateAction<string[]>>
}

function Carousel({
  addProductDialogPage,
  carrouselDisplay,
  highlighted,
  imageSrc,
  setHighlighted,
  setCarrouselImageDisplay,
  setImageSrc
}: ICarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(true);
  };

  const handleDragLeave = () => {
    setHighlighted(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setHighlighted(false);
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFile = (file: File) => {
    if (file.type.startsWith('image/')) {
      setCarrouselImageDisplay(true);
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSrc = e.target?.result as string;
        setImageSrc([...imageSrc, newImageSrc]);
      };
      reader.readAsDataURL(file);
    } else {
      alert(CarrouselText.AlertUpload);
    }
  };

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files?.[0];
    if (files) {
      handleFile(files);
    }
  };

  const handleUpload = () => {
    document.getElementById(CarrouselText.ImageInput)?.click();
  };

  const removeImage = (index: number) => {
    setImageSrc(imageSrc.filter((_, i) => i !== index));
    if (currentIndex >= imageSrc.length - 1 && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
    if (imageSrc.length === 1) {
      setCarrouselImageDisplay(false);
    }
  };

  const handleSlide = (direction: string) => {
    if (!isSliding) {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => {
          let newIndex;
          if (direction === CarrouselText.Next) {
            return (prevIndex + 1) % imageSrc.length;
          } else if (direction === CarrouselText.Back) {
            return (prevIndex - 1 + imageSrc.length) % imageSrc.length;
          } else {
            newIndex = prevIndex;
          }
          return newIndex;
        });
        setIsSliding(false);
      }, 100);
    }
  };

  return (
    <StyleDisplayRow>
      <StyledImgUploadCarousel
        isDisplay={carrouselDisplay}
        addProductDialogPage={addProductDialogPage}
      >
        <StyledTransitionImgCarousel currentIndex={currentIndex}>
          {imageSrc.map((imageSrc, i) => (
            <StyledImgUploadCarouselContent
              key={i}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              isDragOver={highlighted}
              onChange={handleFileInputChange}
              addProductDialogPage={addProductDialogPage}
            >
              <StyledDeleteImageButtonBox>
                <Button
                  action={() => {
                    removeImage(i);
                  }}
                  type={ComponentType.Button}
                  format={Format.Icon}
                  icon={IconName.REMOVE}
                />
              </StyledDeleteImageButtonBox>
              {imageSrc && <ImageComponent url={imageSrc} isImgCarousel />}
            </StyledImgUploadCarouselContent>
          ))}
        </StyledTransitionImgCarousel>
        <StyledBtnArrowCarousel addProductDialogPage={addProductDialogPage}>
          <Button
            action={() => {
              handleSlide(CarrouselText.Back);
            }}
            type={ComponentType.Button}
            format={Format.Icon}
            icon={IconName.ARROW_LEFT}
          />
          <Button
            action={() => {
              handleSlide(CarrouselText.Next);
            }}
            type={ComponentType.Button}
            format={Format.Icon}
            icon={IconName.ARROW_RIGHT}
          />
        </StyledBtnArrowCarousel>
      </StyledImgUploadCarousel>
      <StyledBtnUploadSide
        isDisplay={carrouselDisplay}
        addProductDialogPage={addProductDialogPage}
      >
        <StyledBtnAddImg
          type={ComponentType.Button}
          onClick={() => {
            handleUpload();
          }}
          addProductDialogPage={addProductDialogPage}
        >
          {CarrouselText.Plus}
        </StyledBtnAddImg>
      </StyledBtnUploadSide>
    </StyleDisplayRow>
  );
}

export default Carousel;
