export enum Categories {
  EYES = 'EYES',
  LIPS = 'LIPS',
  FACE = 'FACE',
  NAILS = 'NAILS',
  ACCESS = 'ACCESS',
}

export enum Levels {
  BIGBET = 'BIG BET',
  STRATEGIC = 'STRATEGIC',
  CLASSIC = 'STANDARD',
  PILAR = 'PILAR',
}

export enum Axis {
  MAKEUP = '1',
  SKINCARE = '3',
  ACCESSORIES = '2',
  HOLIDAY = '5',
  MEDIA = '6',
  OTHERS = '4',
}

export enum GeneralStatus {
  BLOCKED = 'BLOCKED',
  OPEN = 'OPEN',
}

export enum ProductStatus {
  CATALOGUE= 'CATALOGUE',
  ONE_SHOT= 'ONE SHOT',
  CATALOGUE_1_YEAR = 'CATALOGUE 1 YEAR',
}

export enum GeographicScope {
  CHINA = 'CHINA',
  NORTHAMERICA = 'NORTHAMERICA',
  NULL = 'NULL',
}

export enum Seasons {
  SPRING = 1,
  SUMMER = 2,
  FALL = 3,
  HOLIDAY = 4,
}
