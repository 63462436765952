import styled, { css } from 'styled-components';

interface IStyledImgUpload {
  isDragOver?: boolean;
  isDisplay?: boolean;
  currentIndex?: number;
  addProductDialogPage?: number
}

interface IStyledBtn {
  isDisplay?: boolean;
  addProductDialogPage?: number
}

interface IStyledImgTransition {
  currentIndex: number
}

export const StyledImgUploadCarousel = styled.div<IStyledImgUpload>`
  display: ${(props) => (props.isDisplay ? 'block' : 'none')};
  position: relative;
  overflow: hidden;
  ${({ addProductDialogPage }) => {
    switch (addProductDialogPage) {
      case 1:
        return css`
          height: 12rem;
          width: 12rem;
        `;
      case 2:
        return css`
          height: 8rem;
          width: 8rem;
          margin: 0.4rem 0;
        `;
    }
  }}
`;

export const StyledImgUploadCarouselContent = styled.div<IStyledImgUpload>`
  display: flex;
  position: relative;
  text-align: center;
  min-width: 100%;
  border-radius: 12px;
  overflow: hidden;
  justify-content: center;
  align-items: flex-start;
  background: lightgrey;
  transition: all 0.3s ease;
  opacity: ${(props) => (props.isDisplay ? '0.7' : '1')};
  ${({ addProductDialogPage }) => {
    switch (addProductDialogPage) {
      case 1:
        return css`
          height: 12rem;
          width: 12rem;
        `;
      case 2:
        return css`
          height: 8rem;
          width: 8rem;
        `;
    }
  }}
`;

export const StyledBtnUploadSide = styled.div<IStyledBtn>`
  display: ${(props) => (props.isDisplay ? 'flex' : 'none')};
  height: 100%;
  width: 2rem;
  align-items: center;
`;

export const StyledBtnAddImg = styled.button<IStyledBtn>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color-button);
  font-size: 1.5rem;
  background-color: var(--button-background-color);
  border: var(--button-background-color);
  border-radius: var(--border-radius);
  width: 100%;
  padding: 0.5rem 0.5rem;
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.6;
    cursor: pointer;
  }
  ${({ addProductDialogPage }) => {
    switch (addProductDialogPage) {
      case 1:
        return css`
          height: 12rem;
        `;
      case 2:
        return css`
          height: 8rem;
        `;
    }
  }}
`;

export const StyledBtnArrowCarousel = styled.div<IStyledBtn>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 1rem;
  position: relative;
  bottom: 3rem;
`;

export const StyledTransitionImgCarousel = styled.div<IStyledImgTransition>`
  display: flex;
  transition: transform 0.3s ease;
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 100}%)`};
`;
