import { AuthState } from '@okta/okta-auth-js';
import { OKTA_API_KEY } from '@utils/constantes.utils';
import { Credentials } from 'types';

export const getCredentials = (
  authState: AuthState,
): Credentials | undefined => {
  const { accessToken, idToken } = authState;

  if (!accessToken || !idToken) {
    return undefined;
  }

  return {
    idToken: idToken.idToken,
    accessToken: accessToken.accessToken,
    apiKey: `${accessToken.claims[OKTA_API_KEY]}`,
  };
};
