import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileUploaderContainer, FileUploaderInput, } from '@components/FileUploader/FileUploader.styled';
import { Button } from '@src/components';
import { FilesText } from '@utils/constantes.utils';
import { ComponentType, Format } from '@utils/enum.utils';
import { StyledText } from '@utils/style.utils';

interface FileUploaderProps {
  handleFiles: (fileNames: string[]) => void;
}

function FileUploader({ handleFiles }: FileUploaderProps) {
  const { t } = useTranslation();
  const [fileNames, setFileNames] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFiles = Array.from(e.target.files).map((file) => file.name);
      setFileNames((prev) => [...prev, ...selectedFiles]);
      handleFiles(selectedFiles);
    }
  };

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <FileUploaderContainer>
      <FileUploaderInput
        ref={fileInputRef}
        type="file"
        multiple
        onChange={handleFileSelect}
        id="file-input"
      />
      <label htmlFor="file-input">
        <Button
          type={ComponentType.Button}
          action={handleClick}
          format={Format.Thin}
          text={t(FilesText.UploadFile)}
        />
      </label>

      {fileNames.length > 0 && (
        <div>
          <StyledText>{FilesText.SelectFile}</StyledText>
          <StyledText type={ComponentType.Comment}>
            {fileNames.join(", ")}
          </StyledText>
        </div>
      )}
    </FileUploaderContainer>
  );
}

export default FileUploader;
