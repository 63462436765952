export enum IconName {
  ADD = 'Add',
  REMOVE = 'Remove',
  WAITING = 'Cached',
  TODO = 'Cached',
  CHECK = 'Check',
  CANCELED = 'Check',
  CROSS = 'Clear',
  NAV_ARROW_RIGHT = 'ArrowForwardIos',
  HOME = 'HomeOutlined',
  SUN = 'WbSunnyOutlined',
  LOGOUT = 'Logout',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_RIGHT = 'ArrowRight',
  LOCK = 'LockOutlined',
  EDIT = 'Edit',
  DOWNLOAD = 'Download',
}
