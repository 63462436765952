import styled from 'styled-components';

export const StyledElementPhoto = styled.div`
  position: relative;
  border-right: 1px solid black;
  width: 50%;
  height: 100%;
`;

export const StyledProductsInfo = styled.div`
  margin-bottom: 0.4rem;
`;
